import {useS3Image} from "@src/hooks/useS3Image";
import VideosList from "@src/components/Videos/VideosList";
import gql from "graphql-tag";
import CircularProgress from "@material-ui/core/CircularProgress";
import {compose, graphql} from 'react-apollo';
import {MySnackBar} from "@src/components/MySnackBar";
import {useState} from "react";

const VideosPage = (props: any) => {
  const {
    addVideo,
    updateVideo,
    showAlert,
    data: {
      adminGetVideos,
      loading,
      error,
      refetch
    }
  } = props;
  const {uploadS3Video, removeFileFromS3, copyS3File} = useS3Image();
  const [saveData, setSaveData] = useState(false)
  return (
    <>
      {(loading || saveData) && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '92dvh',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
        }}>
          <CircularProgress/>
        </div>
      )}
      {
        error && (
          <MySnackBar variant="error" message={error.graphQLErrors[0].message}/>
        )
      }
      <div style={{position: 'relative'}}>
        <div style={{fontWeight: '500', fontSize: '24px'}}>動画管理 Videos</div>
        <VideosList
          uploadS3Video={uploadS3Video}
          removeFileFromS3={removeFileFromS3}
          copyS3File={copyS3File}
          addVideo={addVideo}
          updateVideo={updateVideo}
          getVideos={adminGetVideos}
          showAlert={showAlert}
          setSaveData={setSaveData}
          saveData={saveData}
          refetch={refetch}/>
      </div>

    </>
  );
};

const adminGetVideos = gql`
  query AdminGetVideos {
    adminGetVideos {
      id
      videoUrl
      videoName
      createdAt
      updatedAt
      deletedAt
    }
  }
`

const addVideo = gql`
  mutation AddVideo($videoUrl: String!, $videoName: String!) {
    addVideo(videoUrl: $videoUrl, videoName: $videoName) {
      id
      videoUrl
      videoName
      createdAt
      updatedAt
      deletedAt
    }
  }
`

const updateVideo = gql`
  mutation UpdateVideo($id: ID!, $videoUrl: String!, $videoName: String!) {
    updateVideo(id: $id, videoUrl: $videoUrl, videoName: $videoName) {
      status
      message
    }
  }
`
export const Videos = compose(
  graphql(adminGetVideos, {
    options: {fetchPolicy: 'network-only'}
  }),
  graphql(addVideo, {name: 'addVideo'}),
  graphql(updateVideo, {name: 'updateVideo'})
)(VideosPage)