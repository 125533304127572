import { Button, TextField } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  Map,
  APIProvider,
  MapControl,
  useMap,
  useMapsLibrary,
  useAdvancedMarkerRef,
  ControlPosition,
  Marker,
  AdvancedMarker,
} from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import './style.less';
// const API_KEY = process.env.GOOGLE_MAPS_API_KEY ?? ("AIzaSyDjvZSGlJUBicf9eTC2iSlAjsowcKAtCUE");

const contentTextStyle = {
  marginLeft: '2%',
  marginRight: '2%',
  display: 'flex',
  fontSize: '1vmin',
};
const inputStyle = {
  marginTop: '1px',
  marginBottom: '1em',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  borderRadius: '3px',
};
const inputTimeStyle = {
  width: '100%',
  marginTop: '1px',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  borderRadius: '3px',
};
const itemText = {
  color: '#878787',
  fontSize: '1vmin',
};

const tabsModal = {
  margin: '0 3% 4px',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  gap: '4px',
};
const tabButton = {
  backgroundColor: '#ffffff',
  color: '#000000',
  cursor: 'pointer',
  borderRadius: '0.25rem',
  border: '1px solid rgb(39 39 42)',
};
const timeTheme = createTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
  },
});
const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        border: '#FFFFFF',
      },
      inputMarginDense: {
        paddingTop: '5px',
        paddingBottom: '5px',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '0%',
      },
      colorPrimary: {
        color: '#FFFFFF',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '70px',
      },
    },
  },
});
export const PortModal = ({
  state,
  handleClose,
  onChangeHandler,
  addPort,
  updatePort,
  getGPSGoogleMap,
  seedGoogleMap,
  setModalIndex,
}: any) => {
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <Dialog
      open={state.isModelOpen || state.editPortId}
      onClose={() => {
        handleClose();
      }}
      maxWidth="xl"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle id="confirmation-dialog-title">
        {state.editPortId ? 'ポート編集' : 'ポート作成'}
      </DialogTitle>
      <div style={tabsModal}>
        <button
          style={tabButton}
          type={'button'}
          onClick={() => setModalIndex(1)}>
          日本
        </button>
        <button
          style={tabButton}
          type={'button'}
          onClick={() => setModalIndex(2)}>
          韓国
        </button>
        <button
          style={tabButton}
          type={'button'}
          onClick={() => setModalIndex(3)}>
          中国（簡体字）
        </button>
        <button
          style={tabButton}
          type={'button'}
          onClick={() => setModalIndex(4)}>
          中国（繁体字）
        </button>
        <button
          style={tabButton}
          type={'button'}
          onClick={() => setModalIndex(5)}>
          ベトナム
        </button>
      </div>
      <ValidatorForm
        onSubmit={() => {
          if (state.editPortId) {
            updatePort();
          } else {
            addPort();
          }
        }}>
        {state.modalIndex === 1 && (
          <DialogContent
            style={{
              marginLeft: '3%',
              marginRight: '3%',
              width: '94%',
              backgroundColor: '#F5F5F5',
              alignSelf: 'center',
              flexDirection: 'row',
              display: 'flex',
            }}>
            <DialogContentText
              style={{
                ...contentTextStyle,
                width: '40vmin',
                flexDirection: 'column',
              }}>
              <MuiThemeProvider theme={theme}>
                <div style={itemText}> ポート名*</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.name}
                  onChange={onChangeHandler}
                  name="name"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={itemText}> ポート名（英語）*</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.nameEn}
                  onChange={onChangeHandler}
                  name="nameEn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={itemText}> サブタイトル(備考)</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.note}
                  onChange={onChangeHandler}
                  name="note"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                />
                <div style={{ ...itemText }}> 説明</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.description}
                  onChange={onChangeHandler}
                  name="description"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={{ ...itemText }}> 説明（英語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.descriptionEn}
                  onChange={onChangeHandler}
                  name="descriptionEn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <br />
                <div style={itemText}> 住所*</div>
                <TextField
                  style={{
                    width: '45%',
                    marginTop: '1px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    borderRadius: '3px',
                  }}
                  value={state.zipCode}
                  onChange={onChangeHandler}
                  name="zipCode"
                  id="filled-required"
                  placeholder="000-0000"
                  margin="dense"
                  variant="outlined"
                />
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.address}
                  onChange={onChangeHandler}
                  name="address"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={itemText}> 住所（英語）*</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.addressEn}
                  onChange={onChangeHandler}
                  name="addressEn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />

                <div style={itemText}> GPS座標*</div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    style={{
                      width: '48%',
                      marginTop: '1px',
                      marginRight: '4%',
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      borderRadius: '3px',
                    }}
                    required
                    value={state.longitude}
                    onChange={onChangeHandler}
                    name="longitude"
                    id="filled-required"
                    placeholder="経度"
                    margin="dense"
                    type="number"
                    variant="outlined"
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                  <TextField
                    style={{
                      width: '48%',
                      marginTop: '1px',
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      borderRadius: '3px',
                    }}
                    required
                    value={state.latitude}
                    onChange={onChangeHandler}
                    name="latitude"
                    id="filled-required"
                    placeholder="緯度"
                    margin="dense"
                    type="number"
                    variant="outlined"
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                </div>
              </MuiThemeProvider>
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '30vmin',
                    flexDirection: 'column',
                  }}>
                  <div style={{ ...itemText, position: 'absolute' }}>
                    {' '}
                    営業時間
                  </div>
                  <div style={{ ...itemText, textAlign: 'right' }}>
                    {' '}
                    平日開始時刻
                  </div>
                  <MuiThemeProvider theme={timeTheme}>
                    <TextValidator
                      style={inputTimeStyle}
                      value={state.weekdayStart}
                      onChange={onChangeHandler}
                      name="weekdayStart"
                      id="filled-required"
                      validators={['isValidTime']}
                      errorMessages={['入力された時刻が無効です']}
                      margin="dense"
                      type="time"
                      variant="outlined"
                    />
                    <div style={{ ...itemText, textAlign: 'right' }}>
                      {' '}
                      平日終了時刻
                    </div>
                    <TextValidator
                      style={inputTimeStyle}
                      value={state.weekdayEnd}
                      onChange={onChangeHandler}
                      name="weekdayEnd"
                      id="filled-required"
                      validators={[
                        'isValidTime',
                        'requiredBothInputWeekdayTime',
                        'isValiedWeekdayTime',
                      ]}
                      errorMessages={[
                        '入力された時刻が無効です',
                        '開始時刻と終了時刻の両方を入力してください',
                        '開始時刻よりも後に設定してください',
                      ]}
                      margin="dense"
                      type="time"
                      variant="outlined"
                    />
                    <div style={{ ...itemText, textAlign: 'right' }}>
                      {' '}
                      土日開始時刻
                    </div>
                    <TextValidator
                      style={inputTimeStyle}
                      value={state.weekendStart}
                      onChange={onChangeHandler}
                      name="weekendStart"
                      id="outlined-name"
                      validators={['isValidTime']}
                      errorMessages={['入力された時刻が無効です']}
                      margin="dense"
                      type="time"
                      variant="outlined"
                    />
                    <div style={{ ...itemText, textAlign: 'right' }}>
                      {' '}
                      土日終了時刻
                    </div>
                    <TextValidator
                      style={inputTimeStyle}
                      value={state.weekendEnd}
                      onChange={onChangeHandler}
                      name="weekendEnd"
                      id="outlined-name"
                      validators={[
                        'isValidTime',
                        'requiredBothInputWeekendTime',
                        'isValiedWeekendTime',
                      ]}
                      errorMessages={[
                        '入力された時刻が無効です',
                        '開始時刻と終了時刻の両方を入力してください',
                        '開始時刻よりも後に設定してください',
                      ]}
                      margin="dense"
                      type="time"
                      variant="outlined"
                    />
                  </MuiThemeProvider>
                  <MuiThemeProvider theme={theme}>
                    <div style={itemText}> 営業時間に関する備考*</div>
                    <TextField
                      style={{ ...inputStyle, width: '100%' }}
                      value={state.openingHourNote}
                      onChange={onChangeHandler}
                      name="openingHourNote"
                      id="filled-required"
                      margin="dense"
                      variant="outlined"
                      required
                      multiline
                      rows="3"
                      inputProps={{ style: { fontSize: 12 } }}
                    />
                    <div style={itemText}> ラズパイID*</div>
                    <TextField
                      style={{ ...inputStyle, width: '100%' }}
                      required
                      value={state.rasPiId}
                      onChange={onChangeHandler}
                      name="rasPiId"
                      id="outlined-name"
                      margin="dense"
                      variant="outlined"
                    />
                    {state.editPortId ? null : (
                      <div>
                        <div style={itemText}> ドア数*</div>
                        <TextField
                          style={{ ...inputStyle, width: '25%' }}
                          required
                          value={state.numberOfDoors}
                          onChange={onChangeHandler}
                          name="numberOfDoors"
                          id="filled-required"
                          margin="dense"
                          variant="outlined"
                        />
                      </div>
                    )}
                  </MuiThemeProvider>
                </DialogContentText>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '50vmin',
                    flexDirection: 'column',
                  }}>
                  <MuiThemeProvider theme={theme}>
                    <div style={{ ...itemText }}>
                      {' '}
                      使用状況確認送信メールアドレス
                    </div>
                    <TextField
                      style={{ ...inputStyle, width: '100%' }}
                      value={state.notCleanEmail}
                      onChange={onChangeHandler}
                      name="notCleanEmail"
                      id="filled-required"
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows="4"
                      inputProps={{ style: { fontSize: 12 } }}
                    />
                    <div style={{ ...itemText }}>
                      {' '}
                      ブロック通知送信メールアドレス
                    </div>
                    <TextField
                      style={{ ...inputStyle, width: '100%' }}
                      value={state.notificationEmail}
                      onChange={onChangeHandler}
                      name="notificationEmail"
                      id="filled-required"
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows="4"
                      inputProps={{ style: { fontSize: 12 } }}
                    />
                    <div style={itemText}>利用割合</div>
                    <TextValidator
                      type="number"
                      validators={['minNumber: 0', 'maxNumber: 100']}
                      style={{ ...inputStyle, width: '100%' }}
                      value={state.discountPercent}
                      onChange={onChangeHandler}
                      name="discountPercent"
                      margin="dense"
                      variant="outlined"
                      errorMessages={['最小: 0%', '最大: 100%']}
                    />
                    <div style={itemText}>利用割合適用日</div>
                    <TextValidator
                      style={{ ...inputStyle, width: '100%' }}
                      type="date"
                      value={state.discountedStartDate}
                      validators={
                        state.discountPercent !== null ? ['required'] : []
                      }
                      errorMessages={['このフィールドは必須です']}
                      onChange={onChangeHandler}
                      name="discountedStartDate"
                      margin="dense"
                      variant="outlined"
                    />
                  </MuiThemeProvider>
                </DialogContentText>
              </div>
              <div
                className={'google-map'}
                style={{ width: '100%', height: '100%' }}>
                <APIProvider
                  key={seedGoogleMap}
                  apiKey={'AIzaSyDjvZSGlJUBicf9eTC2iSlAjsowcKAtCUE'}
                  onLoad={() => console.log('Maps API has loaded.')}
                  solutionChannel="GMP_devsite_samples_v3_rgmautocomplete">
                  <Map
                    initialViewState={{
                      longitude: state?.longitude
                        ? parseFloat(state?.longitude)
                        : 134.379711,
                      latitude: state?.latitude
                        ? parseFloat(state?.latitude)
                        : 34.886306,
                      zoom: 13,
                    }}
                    style={{ width: '100%', height: '100%' }}
                    defaultCenter={{
                      lat: state?.latitude
                        ? parseFloat(state?.latitude)
                        : 34.886306,
                      lng: state?.longitude
                        ? parseFloat(state?.longitude)
                        : 134.379711,
                    }}
                    mapId={'5c94003c477c6187'}
                    defaultZoom={12}
                    scrollwheel={true}
                    scaleControl={true}
                    disableDoubleClickZoom={false}
                    gestureHandling={'greedy'}
                    disableDefaultUI
                    onClick={(ev) => getGPSGoogleMap(ev)}>
                    <Marker
                      position={{
                        lat: state?.latitude
                          ? parseFloat(state?.latitude)
                          : 34.886306,
                        lng: state?.longitude
                          ? parseFloat(state?.longitude)
                          : 134.379711,
                      }}
                    />
                    <AdvancedMarker ref={markerRef} position={null} />
                  </Map>
                  <MapControl position={ControlPosition.TOP_LEFT}>
                    <div className="autocomplete-control">
                      <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
                    </div>
                  </MapControl>
                  <MapHandler place={selectedPlace} marker={marker} />
                </APIProvider>
              </div>
            </div>
          </DialogContent>
        )}
        {state.modalIndex === 2 && (
          <DialogContent
            style={{
              marginLeft: '3%',
              marginRight: '3%',
              width: '94%',
              backgroundColor: '#F5F5F5',
              alignSelf: 'center',
              flexDirection: 'row',
              display: 'flex',
            }}>
            <DialogContentText
              style={{
                ...contentTextStyle,
                width: '40vmin',
                flexDirection: 'column',
              }}>
              <MuiThemeProvider theme={theme}>
                <div style={itemText}> ポート名（韓国語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%', padding: '5px 0' }}
                  required
                  value={state.nameKr}
                  onChange={onChangeHandler}
                  name="nameKr"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={{ ...itemText }}> 説明（韓国語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.descriptionKr}
                  onChange={onChangeHandler}
                  name="descriptionKr"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={itemText}> 住所（韓国語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.addressKr}
                  onChange={onChangeHandler}
                  name="addressKr"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />
              </MuiThemeProvider>
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '30vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '50vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
              </div>
            </div>
          </DialogContent>
        )}
        {state.modalIndex === 3 && (
          <DialogContent
            style={{
              marginLeft: '3%',
              marginRight: '3%',
              width: '94%',
              backgroundColor: '#F5F5F5',
              alignSelf: 'center',
              flexDirection: 'row',
              display: 'flex',
            }}>
            <DialogContentText
              style={{
                ...contentTextStyle,
                width: '40vmin',
                flexDirection: 'column',
              }}>
              <MuiThemeProvider theme={theme}>
                <div style={itemText}> ポート名（中国語：簡体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%', padding: '5px 0' }}
                  required
                  value={state.nameCn}
                  onChange={onChangeHandler}
                  name="nameCn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={{ ...itemText }}> 説明（中国語：簡体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.descriptionCn}
                  onChange={onChangeHandler}
                  name="descriptionCn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={itemText}> 住所（中国語：簡体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.addressCn}
                  onChange={onChangeHandler}
                  name="addressCn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />
              </MuiThemeProvider>
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '30vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '50vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
              </div>
            </div>
          </DialogContent>
        )}
        {state.modalIndex === 4 && (
          <DialogContent
            style={{
              marginLeft: '3%',
              marginRight: '3%',
              width: '94%',
              backgroundColor: '#F5F5F5',
              alignSelf: 'center',
              flexDirection: 'row',
              display: 'flex',
            }}>
            <DialogContentText
              style={{
                ...contentTextStyle,
                width: '40vmin',
                flexDirection: 'column',
              }}>
              <MuiThemeProvider theme={theme}>
                <div style={itemText}> ポート名（中国語：繁体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%', padding: '5px 0' }}
                  required
                  value={state.nameCnTraditional}
                  onChange={onChangeHandler}
                  name="nameCnTraditional"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={{ ...itemText }}> 説明（中国語：繁体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.descriptionCnTraditional}
                  onChange={onChangeHandler}
                  name="descriptionCnTraditional"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={itemText}> 住所（中国語：繁体字）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.addressCnTraditional}
                  onChange={onChangeHandler}
                  name="addressCnTraditional"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />
              </MuiThemeProvider>
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '30vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '50vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
              </div>
            </div>
          </DialogContent>
        )}
        {state.modalIndex === 5 && (
          <DialogContent
            style={{
              marginLeft: '3%',
              marginRight: '3%',
              width: '94%',
              backgroundColor: '#F5F5F5',
              alignSelf: 'center',
              flexDirection: 'row',
              display: 'flex',
            }}>
            <DialogContentText
              style={{
                ...contentTextStyle,
                width: '40vmin',
                flexDirection: 'column',
              }}>
              <MuiThemeProvider theme={theme}>
                <div style={itemText}> ポート名（ベトナム語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%', padding: '5px 0' }}
                  required
                  value={state.nameVn}
                  onChange={onChangeHandler}
                  name="nameVn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"></TextField>
                <div style={{ ...itemText }}> 説明（ベトナム語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  value={state.descriptionVn}
                  onChange={onChangeHandler}
                  name="descriptionVn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="4"
                  inputProps={{ style: { fontSize: 12 } }}
                />
                <div style={itemText}> 住所（ベトナム語）</div>
                <TextField
                  style={{ ...inputStyle, width: '100%' }}
                  required
                  value={state.addressVn}
                  onChange={onChangeHandler}
                  name="addressVn"
                  id="filled-required"
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows="3"
                  inputProps={{ style: { fontSize: 12 } }}
                />
              </MuiThemeProvider>
            </DialogContentText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '30vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
                <DialogContentText
                  style={{
                    ...contentTextStyle,
                    width: '50vmin',
                    flexDirection: 'column',
                  }}></DialogContentText>
              </div>
            </div>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            style={{
              paddingTop: '3px',
              paddingBottom: '3px',
            }}
            onClick={() => {
              handleClose();
            }}
            variant="outlined">
            キャンセル
          </Button>
          <Button
            style={{
              marginRight: '3%',
              backgroundColor: '#12C670',
              borderColor: '#12C670',
              color: '#FFFFFF',
              padding: '3px',
            }}
            type="submit"
            variant="outlined">
            {state.editPortId ? '編集' : '作成'}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  marker: google.maps.marker.AdvancedMarkerElement | null;
}

const MapHandler = ({ place, marker }: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
    marker.position = place.geometry?.location;
  }, [map, place, marker]);

  return null;
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

const PlaceAutocomplete = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input ref={inputRef} />
    </div>
  );
};
