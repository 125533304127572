import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, TextField, MenuItem} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import moment from "moment";
import "./style.less";

const contentTextStyle = {
	marginLeft: '2%',
	marginRight: '2%',
	display: 'flex',
	fontSize: '14px',
};
const inputStyle = {
	marginTop: '1px',
	marginBottom: '1em',
	backgroundColor: '#FFFFFF',
	display: 'flex',
	borderRadius: '3px',
};
const inputTimeStyle = {
	width: '100%',
	marginTop: '1px',
	backgroundColor: '#FFFFFF',
	display: 'flex',
	borderRadius: '3px',
};

const selectStyle = {
	width: '100%',
	marginTop: '1px',
	backgroundColor: '#FFFFFF',
	display: 'flex',
	borderRadius: '3px',
	padding: '4px 8px',

	'& .MuiInput-underline::before': {
		border: 'none',
	}
}

const itemText = {
	color: '#878787',
	fontSize: '14px',
	marginTop: '12px'
};
const timeTheme = createTheme({
	palette: {
		primary: green,
	},
	overrides: {
		MuiOutlinedInput: {
			inputMarginDense: {
				paddingTop: '10px',
				paddingBottom: '10px',
			},
		},
	},
});
const theme = createTheme({
	overrides: {
		MuiOutlinedInput: {
			notchedOutline: {
				border: '#FFFFFF',
			},
			inputMarginDense: {
				paddingTop: '5px',
				paddingBottom: '5px',
			},
		},
		MuiIconButton: {
			root: {
				borderRadius: '0%',
			},
			colorPrimary: {
				color: '#FFFFFF',
			},
		},
		MuiSvgIcon: {
			root: {
				fontSize: '70px',
			},
		},
	},
});

const dataSelect = [
	{
		value: 'discountRate',
		label: '割引率',
	},
	{
		value: 'discountAmount',
		label: '値引金額',
	},
	{
		value: 'discountMinutes',
		label: '無料時間（分）',
	},
];

export const CouponModal = ({
	                            state,
	                            handleClose,
	                            updateCoupon,
	                            addCoupon,
	                            deleteCoupon,
	                            recoverCoupon,
	                            isModalOpen,
	                            onChangeHandler,
	                            select,
	                            setSelect,
	                            setState,
	                            errorCompareTime
                            }: any) => {

	const handleChange = (event) => {
		setSelect(event.target.value);
		setState((prev) => ({
			...prev,
			discountRate: 0,
			discountAmount: 0,
			discountMinutes: 0,
		}))
	};
	return (
		<Dialog open={isModalOpen}
		        onClose={handleClose}
		        maxWidth="xl"
		        aria-labelledby="alert-dialog-slide-title"
		        aria-describedby="alert-dialog-slide-description">
			<DialogTitle id="confirmation-dialog-title">
				{state.id ? 'クーポン編集' : 'クーポン作成'}
			</DialogTitle>
			<ValidatorForm onSubmit={() => {
				if (state.id) {
					updateCoupon();
				} else {
					addCoupon();
				}
			}}>
				<DialogContent style={{
					marginLeft: '3%',
					marginRight: '3%',
					width: '94%',
					backgroundColor: '#F5F5F5',
					alignSelf: 'center',
					flexDirection: 'row',
					display: 'flex',
				}}>
					<DialogContentText style={{
						...contentTextStyle,
						width: '33vmin',
						flexDirection: 'column',
					}}>
						<MuiThemeProvider theme={theme}>
							<div style={itemText}> クーポン名*</div>
							<TextValidator
								style={{...inputStyle, width: '100%'}}
								value={state.name}
								onChange={onChangeHandler}
								name="name"
								validators={['required', 'minStringLength:1']}
								errorMessages={['クーポン名が必須です。', '最低文字数は1です。']}
								id="filled-required"
								margin="dense"
								variant="outlined"/>
							<div style={itemText}> クーポンコード*</div>
							<TextValidator
								style={{...inputStyle, width: '100%'}}
								value={state.couponCode}
								onChange={onChangeHandler}
								name="couponCode"
								validators={['minStringLength:6', 'maxStringLength:15']}
								errorMessages={['最低文字数は6です。', '最大文字数は15です。']}
								id="filled-required"
								margin="dense"
								variant="outlined"/>
						</MuiThemeProvider>
					</DialogContentText>
					<DialogContentText style={{
						...contentTextStyle,
						width: '33vmin',
						flexDirection: 'column',
					}}>
						<MuiThemeProvider theme={timeTheme}>
							<div style={itemText}> 割引タイプ*</div>
							<TextField
								style={{...selectStyle}}
								id="standard-select-discount"
								select
								value={select}
								onChange={handleChange}
							>
								{dataSelect.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							{
								select === 'discountRate' &&
                  <>
                      <div style={itemText}> 割引率*</div>
                      <TextValidator
                          style={inputTimeStyle}
                          value={state.discountRate ? state.discountRate : 0}
                          onChange={onChangeHandler}
                          name="discountRate"
                          id="filled-required"
                          validators={['required', 'minNumber:1']}
                          errorMessages={['割引率が必須です。', '数値は1大きくなければなりません']}
                          margin="dense"
                          type="number"
                          variant="outlined"
                      />
                  </>
							}
							{
								select === 'discountAmount' &&
                  <>
                      <div style={itemText}> 値引金額*</div>
                      <TextValidator
                          style={inputTimeStyle}
                          value={state.discountAmount ? state.discountAmount : 0}
                          onChange={onChangeHandler}
                          name="discountAmount"
                          id="filled-required"
                          validators={['required', 'minNumber:1', 'maxNumber:10000']}
                          errorMessages={['値引金額が必須です。', '数値は1大きくなければなりません', '数値は10000小さくなければなりません']}
                          margin="dense"
                          type="number"
                          variant="outlined"
                      />
                  </>
							}
							{
								select === 'discountMinutes' &&
                  <>
                      <div style={itemText}> 無料時間（分）*</div>
                      <TextValidator
                          style={inputTimeStyle}
                          value={state.discountMinutes ? state.discountMinutes : 0}
                          onChange={onChangeHandler}
                          name="discountMinutes"
                          id="filled-required"
                          validators={['required', 'minNumber:1']}
                          errorMessages={['無料時間（分）が必須です。', '数値は1大きくなければなりません']}
                          margin="dense"
                          type="number"
                          variant="outlined"
                      />
                  </>
							}

							<div style={itemText}> 利用回数*</div>
							<TextValidator
								style={inputTimeStyle}
								value={state.useLimit}
								onChange={onChangeHandler}
								name="useLimit"
								id="filled-required"
								validators={['required', 'minNumber:1', 'maxNumber:100']}
								errorMessages={['利用回数が必須です。', '数値は1大きくなければなりません', '数値は100小さくなければなりません']}
								margin="dense"
								type="number"
								variant="outlined"
							/>
						</MuiThemeProvider>
					</DialogContentText>
					<DialogContentText style={{
						...contentTextStyle,
						width: '33vmin',
						flexDirection: 'column',
					}}>
						<MuiThemeProvider theme={timeTheme}>
							<div style={itemText}> クーポン適用日*</div>
							<TextValidator
								style={inputTimeStyle}
								value={state.expStartTime ? moment.utc(state.expStartTime).format('YYYY-MM-DD HH:mm') : null}
								onChange={onChangeHandler}
								name="expStartTime"
								id="filled-required"
								validators={['required']}
								errorMessages={['クーポン適用日が必須です。']}
								margin="dense"
								type="datetime-local"
								variant="outlined"
							/>
							<div style={itemText}> クーポン有効期限日*</div>
							<TextValidator
								style={inputTimeStyle}
								value={state.expEndTime ? moment.utc(state.expEndTime).format('YYYY-MM-DD HH:mm') : null}
								onChange={onChangeHandler}
								name="expEndTime"
								id="filled-required"
								validators={['required']}
								errorMessages={['クーポン有効期限日が必須です。']}
								margin="dense"
								type="datetime-local"
								variant="outlined"
								error={errorCompareTime ? errorCompareTime : null}
								helperText={errorCompareTime ? errorCompareTime : null}
							/>
						</MuiThemeProvider>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{
						state.id && state.deletedAt === null && <Button
                  style={{
										paddingTop: '3px',
										paddingBottom: '3px',
										backgroundColor: '#ff0000',
										borderColor: '#ff0000',
										color: '#fff'
									}}
                  onClick={deleteCoupon}
                  variant="outlined">
                  削除
              </Button>
					}
					{
						state.id && state.deletedAt !== null && <Button
							style={{
								paddingTop: '3px',
								paddingBottom: '3px',
								backgroundColor: '#27c8ff',
								borderColor: '#27c8ff',
								color: '#fff'
							}}
							onClick={recoverCoupon}
							variant="outlined">
							削除取消
						</Button>
					}
					<Button
						style={{
							paddingTop: '3px',
							paddingBottom: '3px',
						}}
						onClick={() => {
							handleClose();
						}}
						variant="outlined">
						キャンセル
					</Button>
					{
						state.id ? <Button
								style={{
									marginRight: '3%',
									backgroundColor: '#12C670',
									borderColor: '#12C670',
									color: '#FFFFFF',
									padding: '3px',
								}}
								type="submit"
								variant="outlined">
								編集
							</Button> :
							<Button
								style={{
									marginRight: '3%',
									backgroundColor: '#12C670',
									borderColor: '#12C670',
									color: '#FFFFFF',
									padding: '3px',
								}}
								type="submit"
								variant="outlined">
								作成
							</Button>
					}
				</DialogActions>
			</ValidatorForm>
		</Dialog>
	);
};