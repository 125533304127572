import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { PortsComponent } from '@src/components/Ports/component';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { ValidatorForm } from 'react-material-ui-form-validator';

class Ports extends React.Component<any, any> {
  unsubscribe: any = null;

  state: any = {
    isModelOpen: this.props.isModalOpen || false,
    editPortId: null,
    doorId: null,
    editDoorId: null,
    doorCode: null,
    portId: null,
    rasPiId: '',
    name: '',
    nameEn: '',
    nameKr: '',
    nameCn: '',
    nameCnTraditional: '',
    nameVn: '',
    description: '',
    descriptionEn: '',
    descriptionKr: '',
    descriptionCn: '',
    descriptionCnTraditional: '',
    descriptionVn: '',
    zipCode: null,
    address: '',
    addressEn: '',
    addressKr: '',
    addressCn: '',
    addressCnTraditional: '',
    addressVn: '',
    weekdayStart: null,
    weekdayEnd: null,
    weekendStart: null,
    weekendEnd: null,
    openingHourNote: '',
    numberOfDoors: '',
    longitude: null,
    latitude: null,
    note: '',
    notCleanEmail: '',
    notificationEmail: '',
    discountPercent: null,
    discountedStartDate: null,
    seedGoogleMap: 1,
    modalIndex: 1,
  };

  componentDidMount() {
    this.unsubscribe = this.props.subscribe();
    ValidatorForm.addValidationRule('isValidTime', (value) => {
      if (!value) {
        return true; // This is optional field
      }
      return moment(value, 'HH:mm').isValid();
    });
    ValidatorForm.addValidationRule('requiredBothInputWeekdayTime', (value) => {
      const { weekdayStart } = this.state;
      return (weekdayStart && value) || (!weekdayStart && !value);
    });
    ValidatorForm.addValidationRule('requiredBothInputWeekendTime', (value) => {
      const { weekendStart } = this.state;
      return (weekendStart && value) || (!weekendStart && !value);
    });
    ValidatorForm.addValidationRule('isValiedWeekdayTime', (value) => {
      const { weekdayStart } = this.state;
      if (!weekdayStart) {
        return true;
      }
      if (moment(weekdayStart, 'HH:mm').isBefore(moment(value, 'HH:mm'))) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule('isValiedWeekendTime', (value) => {
      const { weekendStart } = this.state;
      if (!weekendStart) {
        return true;
      }
      if (moment(weekendStart, 'HH:mm').isBefore(moment(value, 'HH:mm'))) {
        return true;
      }
      return false;
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'latitude' || e.target.name === 'longitude') {
      this.resetMap();
    }
  };

  addPort = async () => {
    const checkName = await this.props.portNameExists({
      variables: {
        name: this.state.name,
      },
    });
    if (checkName?.data?.portNameExists) {
      this.props.showAlert('ポート名が確かに存在しています', 'error');
      return;
    }
    const checkRasPiId = await this.props.rasPiIdExists({
      variables: {
        rasPiId: this.state.rasPiId,
      },
    });
    if (checkRasPiId?.data?.rasPiIdExists) {
      this.props.showAlert('ラズパイIDが確かに存在しています', 'error');
      return;
    }
    const checkNameEn = await this.props.portNameEnExists({
      variables: {
        nameEn: this.state.nameEn,
      },
    });
    if (checkNameEn?.data?.portNameEnExists) {
      this.props.showAlert('ポート名（英語）が確かに存在しています', 'error');
      return;
    }
    if (this.state.nameKr) {
      const checkNameKr = await this.props.portNameKrExists({
        variables: {
          nameKr: this.state.nameKr,
        },
      });
      if (checkNameKr?.data?.portNameKrExists) {
        this.props.showAlert(
          'ポート名（韓国語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCn) {
      const checkNameCn = await this.props.portNameCnExists({
        variables: {
          nameCn: this.state.nameCn,
        },
      });
      if (checkNameCn?.data?.portNameCnExists) {
        this.props.showAlert(
          'ポート名（中国語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCnTraditional) {
      const checkNameCnTraditional =
        await this.props.portNameCnTraditionalExists({
          variables: {
            nameCnTraditional: this.state.nameCnTraditional,
          },
        });
      if (checkNameCnTraditional?.data?.portNameCnTraditionalExists) {
        this.props.showAlert(
          'ポート名（中国語：繁体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameVn) {
      const checkNameVn = await this.props.portNameVnExists({
        variables: {
          nameVn: this.state.nameVn,
        },
      });
      if (checkNameVn?.data?.portNameVnExists) {
        this.props.showAlert(
          'ポート名（ベトナム語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    await this.props.addPort({
      variables: {
        data: {
          note: this.state.note,
          name: this.state.name,
          nameEn: this.state.nameEn,
          nameKr: this.state.nameKr,
          nameCn: this.state.nameCn,
          nameCnTraditional: this.state.nameCnTraditional,
          nameVn: this.state.nameVn,
          rasPiId: this.state.rasPiId,
          address: this.state.address,
          zipCode: this.state.zipCode,
          addressEn: this.state.addressEn,
          addressKr: this.state.addressKr,
          addressCn: this.state.addressCn,
          addressCnTraditional: this.state.addressCnTraditional,
          addressVn: this.state.addressVn,
          descriptionEn: this.state.descriptionEn,
          descriptionKr: this.state.descriptionKr,
          descriptionCn: this.state.descriptionCn,
          descriptionCnTraditional: this.state.descriptionCnTraditional,
          descriptionVn: this.state.descriptionVn,
          openingHourNote: this.state.openingHourNote,
          weekdayStart: moment(this.state.weekdayStart, 'HH:mm'),
          weekdayEnd: moment(this.state.weekdayEnd, 'HH:mm'),
          weekendStart: moment(this.state.weekendStart, 'HH:mm'),
          weekendEnd: moment(this.state.weekendEnd, 'HH:mm'),
          numberOfDoors: parseInt(this.state.numberOfDoors, 10),
          longitude: parseFloat(this.state.longitude),
          latitude: parseFloat(this.state.latitude),
          notCleanEmail: this.state.notCleanEmail,
          notificationEmail: this.state.notificationEmail,
          discountPercent: +this.state.discountPercent,
          discountedStartDate: this.state.discountedStartDate,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState((prevState) => ({
      isModelOpen: !prevState.isModelOpen,
      modalIndex: 1,
    }));
    this.handleClose();
    this.props.history.push('/ports');
  };

  setUpdatePort = (port) => {
    const weekdayStart = moment(port.weekdayStart);
    const weekdayEnd = moment(port.weekdayEnd);
    const weekendStart = moment(port.weekendStart);
    const weekendEnd = moment(port.weekendEnd);
    this.setState({
      editPortId: port.id,
      rasPiId: port.rasPiId,
      name: port.name,
      nameEn: port.nameEn,
      nameKr: port.nameKr,
      nameCn: port.nameCn,
      nameCnTraditional: port.nameCnTraditional,
      nameVn: port.nameVn,
      description: port.description,
      descriptionEn: port.descriptionEn,
      descriptionKr: port.descriptionKr,
      descriptionCn: port.descriptionCn,
      descriptionCnTraditional: port.descriptionCnTraditional,
      descriptionVn: port.descriptionVn,
      zipCode: port.zipCode,
      address: port.address,
      addressEn: port.addressEn,
      addressKr: port.addressKr,
      addressCn: port.addressCn,
      addressCnTraditional: port.addressCnTraditional,
      addressVn: port.addressVn,
      weekdayStart: weekdayStart.isValid()
        ? weekdayStart.format('HH:mm')
        : null,
      weekdayEnd: weekdayEnd.isValid() ? weekdayEnd.format('HH:mm') : null,
      weekendStart: weekendStart.isValid()
        ? weekendStart.format('HH:mm')
        : null,
      weekendEnd: weekendEnd.isValid() ? weekendEnd.format('HH:mm') : null,
      openingHourNote: port.openingHourNote,
      numberOfDoors: port.numberOfDoors,
      longitude: port.longitude,
      latitude: port.latitude,
      note: port.note,
      notCleanEmail: port.notCleanEmail,
      notificationEmail: port.notificationEmail,
      discountPercent: +port.discountPercent,
      discountedStartDate: port.discountedStartDate
        ? moment(port.discountedStartDate).format('YYYY-MM-DD')
        : null,
    });
  };

  updatePort = async () => {
    const checkName = await this.props.portNameExists({
      variables: {
        name: this.state.name,
        id: this.state.editPortId,
      },
    });
    if (checkName?.data?.portNameExists) {
      this.props.showAlert('ポート名が確かに存在しています', 'error');
      return;
    }
    const checkRasPiId = await this.props.rasPiIdExists({
      variables: {
        rasPiId: this.state.rasPiId,
        id: this.state.editPortId,
      },
    });
    if (checkRasPiId?.data?.rasPiIdExists) {
      this.props.showAlert('ラズパイIDが確かに存在しています', 'error');
      return;
    }
    const checkNameEn = await this.props.portNameEnExists({
      variables: {
        nameEn: this.state.nameEn,
        id: this.state.editPortId,
      },
    });
    if (checkNameEn?.data?.portNameEnExists) {
      this.props.showAlert('ポート名（英語）が確かに存在しています', 'error');
      return;
    }
    if (this.state.nameKr) {
      const checkNameKr = await this.props.portNameKrExists({
        variables: {
          nameKr: this.state.nameKr,
          id: this.state.editPortId,
        },
      });
      if (checkNameKr?.data?.portNameKrExists) {
        this.props.showAlert(
          'ポート名（韓国語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCn) {
      const checkNameCn = await this.props.portNameCnExists({
        variables: {
          nameCn: this.state.nameCn,
          id: this.state.editPortId,
        },
      });
      if (checkNameCn?.data?.portNameCnExists) {
        this.props.showAlert(
          'ポート名（中国語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCnTraditional) {
      const checkNameCnTraditional =
        await this.props.portNameCnTraditionalExists({
          variables: {
            nameCnTraditional: this.state.nameCnTraditional,
            id: this.state.editPortId,
          },
        });
      if (checkNameCnTraditional?.data?.portNameCnTraditionalExists) {
        this.props.showAlert(
          'ポート名（中国語：繁体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameVn) {
      const checkNameVn = await this.props.portNameVnExists({
        variables: {
          nameVn: this.state.nameVn,
          id: this.state.editPortId,
        },
      });
      if (checkNameVn?.data?.portNameVnExists) {
        this.props.showAlert(
          'ポート名（ベトナム語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    await this.props.updatePort({
      variables: {
        portId: this.state.editPortId,
        data: {
          note: this.state.note,
          name: this.state.name,
          nameEn: this.state.nameEn,
          nameKr: this.state.nameKr,
          nameCn: this.state.nameCn,
          nameCnTraditional: this.state.nameCnTraditional,
          nameVn: this.state.nameVn,
          rasPiId: this.state.rasPiId,
          zipCode: this.state.zipCode,
          address: this.state.address,
          addressEn: this.state.addressEn,
          addressKr: this.state.addressKr,
          addressCn: this.state.addressCn,
          addressCnTraditional: this.state.addressCnTraditional,
          addressVn: this.state.addressVn,
          description: this.state.description,
          descriptionEn: this.state.descriptionEn,
          descriptionKr: this.state.descriptionKr,
          descriptionCn: this.state.descriptionCn,
          descriptionCnTraditional: this.state.descriptionCnTraditional,
          descriptionVn: this.state.descriptionVn,
          notCleanEmail: this.state.notCleanEmail,
          weekdayStart: moment(this.state.weekdayStart, 'HH:mm'),
          weekdayEnd: moment(this.state.weekdayEnd, 'HH:mm'),
          weekendStart: moment(this.state.weekendStart, 'HH:mm'),
          weekendEnd: moment(this.state.weekendEnd, 'HH:mm'),
          openingHourNote: this.state.openingHourNote,
          longitude: parseFloat(this.state.longitude),
          latitude: parseFloat(this.state.latitude),
          notificationEmail: this.state.notificationEmail,
          discountPercent: +this.state.discountPercent,
          discountedStartDate: this.state.discountedStartDate,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ editPortId: null });
    this.handleClose();
  };

  updatePortImageSoftDelete = async ({ portId, imageUrlKey }) => {
    await this.props.updatePort({
      variables: {
        portId: portId,
        data: {
          [imageUrlKey]: '',
        },
      },
    });
    await this.props.portsQuery.refetch();
  };

  deleteDoor = async (doorId) => {
    await this.props.deleteDoor({
      variables: {
        doorId,
      },
    });
    this.props.portsQuery.refetch();
    this.setState({ doorId: null });
  };

  deleteDoorCancel = () => {
    this.setState({ doorId: null });
  };

  updateDoorCancel = () => {
    this.setState({
      editDoorId: null,
    });
  };

  updateDoor = async () => {
    await this.props.updateDoor({
      variables: {
        doorId: this.state.editDoorId,
        data: {
          code: this.state.doorCode,
        },
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ editDoorId: null });
  };

  addDoorToPort = async (portId) => {
    await this.props.addDoorToPort({
      variables: {
        portId,
      },
    });
    await this.props.portsQuery.refetch();
    this.setState({ portId: null });
  };

  addDoorToPortCancel = () => {
    this.setState({ portId: null });
  };

  setAddDoorToPort = (portId) => {
    this.setState({
      portId,
    });
  };

  handleOpen = () => {
    this.props.history.push('/ports/add');
    this.setState({
      isModelOpen: true,
      modalIndex: 1,
    });
  };

  handleClose = () => {
    this.props.history.push('/ports');
    this.setState({
      isModelOpen: false,
      editPortId: null,
      doorId: null,
      editDoorId: null,
      doorCode: null,
      portId: null,
      rasPiId: '',
      name: '',
      nameEn: '',
      nameKr: '',
      nameCn: '',
      nameCnTraditional: '',
      nameVn: '',
      description: '',
      descriptionEn: '',
      descriptionKr: '',
      descriptionCn: '',
      descriptionCnTraditional: '',
      descriptionVn: '',
      address: '',
      addressEn: '',
      addressKr: '',
      addressCn: '',
      addressCnTraditional: '',
      addressVn: '',
      zipCode: null,
      weekdayStart: null,
      weekdayEnd: null,
      weekendStart: null,
      weekendEnd: null,
      openingHourNote: '',
      numberOfDoors: '',
      longitude: null,
      latitude: null,
      note: '',
      notCleanEmail: '',
      notificationEmail: '',
      discountPercent: null,
      discountedStartDate: null,
      seedGoogleMap: 1,
      modalIndex: 1,
    });
  };
  resetMap = () => {
    this.setState({ seedGoogleMap: Math.random() });
  };
  openDoor = async (doorId) => {
    try {
      await this.props.openDoor({
        variables: {
          doorId,
        },
      });
      this.props.showAlert('Successfully opened the door', 'success');
    } catch (error) {
      this.props.showAlert(
        error.message.replace('GraphQL error: ', ''),
        'error',
      );
    }
  };

  editDoor = (doorCode, editDoorId) => {
    this.setState({
      doorCode,
      editDoorId,
    });
  };

  setDeleteDoor = (doorId) => {
    this.setState({
      doorId,
    });
  };

  checkRasPi = async (rasPiId) => {
    try {
      const result = await this.props.checkRasPi({
        variables: {
          rasPiId,
        },
      });
      if (result?.data?.checkRasPi) {
        this.props.showAlert(
          'ラズパイのチェック機能送信に成功しました',
          'success',
        );
      }
    } catch (error) {
      this.props.showAlert(
        error.message.replace(
          'check error: ',
          'ラズパイのチェック機能送信に失敗しました',
        ),
        'error',
      );
    }
  };
  getGPSGoogleMap = (ev) => {
    this.setState({
      latitude: parseFloat(ev?.detail?.latLng?.lat),
      longitude: parseFloat(ev?.detail?.latLng?.lng),
    });
  };
  rebootRasPi = async (rasPiId) => {
    const alert = confirm('ラズパイを再起動してよろしいですか？');
    if (alert) {
      try {
        const result = await this.props.rebootRasPi({
          variables: {
            rasPiId,
          },
        });
        if (result?.data?.rebootRasPi) {
          this.props.showAlert('ラズパイ再起動送信に成功しました', 'success');
        }
      } catch (error) {
        this.props.showAlert(
          error.message.replace(
            'reboot error: ',
            'ラズパイ再起動送信に失敗しました',
          ),
          'error',
        );
      }
    }
  };

  setModalIndex = (index: number) => {
    this.setState({ modalIndex: index });
  };

  render() {
    const {
      portsQuery: { ports, loading, error, refetch },
      isSystemAdmin,
    } = this.props;
    if (loading) return <CircularProgress />;
    if (error)
      return (
        <MySnackBar variant="error" message={error.graphQLErrors[0].message} />
      );
    return (
      <PortsComponent
        getGPSGoogleMap={this.getGPSGoogleMap}
        handleOpen={this.handleOpen}
        handleClose={this.handleClose}
        onChangeHandler={this.onChangeHandler}
        ports={ports}
        doorId={this.state.doorId}
        editDoorId={this.state.editDoorId}
        editDoor={this.editDoor}
        portId={this.state.portId}
        openDoor={this.openDoor}
        addDoorToPort={this.addDoorToPort}
        addDoorToPortCancel={this.addDoorToPortCancel}
        setAddDoorToPort={this.setAddDoorToPort}
        addPort={this.addPort}
        deleteDoor={this.deleteDoor}
        deleteDoorCancel={this.deleteDoorCancel}
        updateDoor={this.updateDoor}
        updateDoorCancel={this.updateDoorCancel}
        setDeleteDoor={this.setDeleteDoor}
        doorCode={this.state.doorCode}
        isSystemAdmin={isSystemAdmin}
        updatePort={this.updatePort}
        updatePortImageSoftDelete={this.updatePortImageSoftDelete}
        setUpdatePort={this.setUpdatePort}
        checkRasPi={this.checkRasPi}
        rebootRasPi={this.rebootRasPi}
        state={this.state}
        refetch={refetch}
        seedGoogleMap={this.state.seedGoogleMap}
        setModalIndex={this.setModalIndex}
      />
    );
  }
}

const portsQuery = gql`
  query portsQuery {
    ports {
      id
      name
      nameEn
      numberOfDoors
      rasPiId
      address
      addressEn
      nameKr
      nameCn
      nameCnTraditional
      nameVn
      addressKr
      addressCn
      addressCnTraditional
      addressVn
      zipCode
      longitude
      latitude
      weekdayStart
      weekdayEnd
      weekendStart
      weekendEnd
      openingHourNote
      description
      descriptionEn
      descriptionKr
      descriptionCn
      descriptionCnTraditional
      descriptionVn
      note
      imageUrl1
      imageUrl2
      imageUrl3
      notCleanEmail
      notificationEmail
      discountPercent
      discountedStartDate
      doors {
        id
        code
        status
        isOpen
        babyCar {
          id
          code
          status
          cleaningStatus
        }
      }
    }
  }
`;
const openDoor = gql`
  mutation OPEN_DOOR($doorId: ID!) {
    openDoorAdmin(doorId: $doorId)
  }
`;

const DOOR_SUBSCRIPTION = gql`
  subscription DOOR_SUBSCRIPTION {
    doorSubscription {
      id
      code
      status
      isOpen
    }
  }
`;

const ADDPORT = gql`
  mutation ADD_PORT($data: AddPortInput!) {
    addPort(data: $data) {
      id
    }
  }
`;
const DELETEDOOR = gql`
  mutation DELETE_DOOR($doorId: ID!) {
    deleteDoor(doorId: $doorId) {
      id
    }
  }
`;

const UPDATEDOOR = gql`
  mutation UPDATE_DOOR($doorId: ID!, $data: DoorUpdateInput) {
    updateDoor(doorId: $doorId, data: $data) {
      code
    }
  }
`;
const ADDDOORTOPORT = gql`
  mutation ADD_DOOR_TO_PROT($portId: ID!) {
    addDoorToPort(portId: $portId) {
      id
    }
  }
`;
const UPDATE_PORT = gql`
  mutation updatePort($portId: ID!, $data: PortUpdateInput) {
    updatePort(portId: $portId, data: $data) {
      id
    }
  }
`;

const CHECK_RASPI = gql`
  mutation checkRasPi($rasPiId: String!) {
    checkRasPi(rasPiId: $rasPiId)
  }
`;

const REBOOT_RASPI = gql`
  mutation rebootRasPi($rasPiId: String!) {
    rebootRasPi(rasPiId: $rasPiId)
  }
`;

const EXISTS_RASPIID = gql`
  mutation rasPiIdExists($rasPiId: String!, $id: String) {
    rasPiIdExists(rasPiId: $rasPiId, id: $id)
  }
`;
const EXISTS_NAME_EN = gql`
  mutation portNameEnExists($nameEn: String!, $id: String) {
    portNameEnExists(nameEn: $nameEn, id: $id)
  }
`;
const EXISTS_NAME_KR = gql`
  mutation portNameKrExists($nameKr: String!, $id: String) {
    portNameKrExists(nameKr: $nameKr, id: $id)
  }
`;
const EXISTS_NAME_CN = gql`
  mutation portNameCnExists($nameCn: String!, $id: String) {
    portNameCnExists(nameCn: $nameCn, id: $id)
  }
`;
const EXISTS_NAME_VN = gql`
  mutation portNameVnExists($nameVn: String!, $id: String) {
    portNameVnExists(nameVn: $nameVn, id: $id)
  }
`;
const EXISTS_NAME_CN_TRADITIONAL = gql`
  mutation portNameCnTraditionalExists(
    $nameCnTraditional: String!
    $id: String
  ) {
    portNameCnTraditionalExists(nameCnTraditional: $nameCnTraditional, id: $id)
  }
`;
const EXISTS_NAME = gql`
  mutation portNameExists($name: String!, $id: String) {
    portNameExists(name: $name, id: $id)
  }
`;

export const PortsComponent1 = compose(
  graphql(UPDATE_PORT, {
    name: `updatePort`,
  }),
  graphql(ADDDOORTOPORT, {
    name: `addDoorToPort`,
  }),
  graphql(UPDATEDOOR, {
    name: `updateDoor`,
  }),
  graphql(DELETEDOOR, {
    name: `deleteDoor`,
  }),
  graphql(ADDPORT, {
    name: 'addPort',
  }),
  graphql(CHECK_RASPI, {
    name: `checkRasPi`,
  }),
  graphql(REBOOT_RASPI, {
    name: `rebootRasPi`,
  }),
  graphql(EXISTS_RASPIID, {
    name: 'rasPiIdExists',
  }),
  graphql(EXISTS_NAME_EN, {
    name: 'portNameEnExists',
  }),
  graphql(EXISTS_NAME_KR, {
    name: 'portNameKrExists',
  }),
  graphql(EXISTS_NAME_CN, {
    name: 'portNameCnExists',
  }),
  graphql(EXISTS_NAME_VN, {
    name: 'portNameVnExists',
  }),
  graphql(EXISTS_NAME_CN_TRADITIONAL, {
    name: 'portNameCnTraditionalExists',
  }),
  graphql(EXISTS_NAME, {
    name: 'portNameExists',
  }),
  graphql(portsQuery, {
    name: 'portsQuery',
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props: any) => ({
      ...props,
      subscribe: () =>
        props.portsQuery.subscribeToMore({
          document: DOOR_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }: any) => {
            if (!subscriptionData.data) {
              return prev;
            }
            return prev;
          },
        }),
    }),
  }),
  graphql(openDoor, {
    name: 'openDoor',
  }),
)(Ports);
