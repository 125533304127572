import { useEffect, useState } from 'react';
import Dashboard from '@material-ui/icons/Dashboard';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Business from '@material-ui/icons/Business';
import People from '@material-ui/icons/People';
import Chat from '@material-ui/icons/Chat';
import ChildFriendly from '@material-ui/icons/ChildFriendly';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Home from '@material-ui/icons/Home';
import Store from '@material-ui/icons/Store';
import { Menu, Layout, Typography, Card, Row, Col, Button, Badge } from 'antd';
import gql from 'graphql-tag';
import {useMutation, useQuery} from '@apollo/client';
import { checkAdminNotSeenChatCount } from '@src/generated/graphql';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import {firebaseCheckSupported, requestPermissions} from "@src/firebaseConfig";
import {getMessaging, onMessage} from "@firebase/messaging";
import {showFail, showSuccess} from "@src/components/Alert";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
import './style.less';

const { Header, Sider, Content, Footer } = Layout;

const { Text } = Typography;

const LOGOUT = gql`
  mutation LOGOUT {
    logout
  }
`;

const drawerItems = [
  {
    route: '/',
    icon: Dashboard,
    primary: 'Dashboard',
    secondary: 'ダッシュボード',
  },
  {
    route: '/chats',
    icon: Chat,
    primary: 'Private Chats',
    secondary: 'プライベートチャット',
  },
  {
    route: '/publicchats',
    icon: Chat,
    primary: 'Public Chats',
    secondary: 'パブリックチャット',
  },
  {
    route: '/ports',
    icon: Business,
    primary: 'Ports',
    secondary: 'ポート',
  },
  {
    route: '/facilities',
    icon: Home,
    primary: 'Facilities',
    secondary: '周辺施設',
  },
  {
    route: '/babycars',
    icon: ChildFriendly,
    primary: 'BabyCars',
    secondary: 'ベビーカー',
  },
  {
    route: '/customers',
    icon: People,
    primary: 'Customers',
    secondary: 'ユーザー',
  },
  {
    route: '/products',
    icon: Store,
    primary: 'Products',
    secondary: '商品',
  },
  {
    route: '/manager',
    icon: BusinessCenter,
    primary: 'Manager',
    secondary: 'マネージャー',
  },
  {
    route: '/coupons',
    icon: ConfirmationNumberIcon,
    primary: 'Coupons',
    secondary: 'クーポン',
  },
  {
    route: '/videos',
    icon: VideoLibraryIcon,
    primary: 'Videos',
    secondary: '動画管理',
  },
  {
    route: '/banners',
    icon: PhotoLibraryIcon,
    primary: 'Top Slider',
    secondary: 'バナー',
  },
];
export const CHECK_ADMIN_NOT_SEEN_COUNT_QUERY = gql`
  query checkAdminNotSeenChatCount {
    checkAdminNotSeenChatCount {
      private
      public
    }
  }
`;
const headerHeight = 60;
export const HomeComponent = ({ signOut, history, children }: any) => {
  const chatIdOfReservations = location.pathname;
  //バッジを一時的に非表示 92行目
  const [cnt1, setCnt1] = useState(0);
  const [cnt2, setCnt2] = useState(0);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const { data } = useQuery<checkAdminNotSeenChatCount>(
    CHECK_ADMIN_NOT_SEEN_COUNT_QUERY,
  );
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 1000) {
      setSiderCollapsed(true)
    } else {
      setSiderCollapsed(false)
    }
  }, [width]);
  useEffect(() => {
    if (data) {
      setCnt1(data.checkAdminNotSeenChatCount?.private || 0);
      setCnt2(data.checkAdminNotSeenChatCount?.public || 0);
    }
  }, [data]);

  const [logout, {error }] = useMutation(LOGOUT);

  useEffect(() => {
    firebaseCheckSupported().then((resp)=>{
      if (resp) {
        requestPermissions()
        const messaging = getMessaging()

        onMessage(messaging, (payload) => {
          if (payload?.notification?.body) {
            console.log('Message received. ', payload);
            if (payload?.notification?.body.includes('決済失敗')){
              showFail({ description: payload?.notification?.body });
            }else {
              showSuccess({ description: payload?.notification?.body });
            }
          }
        });
      }
    }).catch(err => console.log(err));

  }, []);

  const signOutAdmin = async () => {
    try {
      await logout()
      if (!error) {
        signOut()
      }
    }catch (err) {
      console.log(error)
    }
  }

  return (
    <Layout style={{ zIndex: '10' }}>
      <Sider
        width={'240px'}
        theme="light"
        collapsed={siderCollapsed}
        collapsedWidth={0}>
        <Menu style={{ marginTop: '60px', border: 'none' }}>
          <Menu.ItemGroup
            key="g1"
            title={
              <div
                style={{
                  textAlign: siderCollapsed ? 'center' : 'left',
                  fontWeight: '500',
                  fontSize: '16px',
                  color: '#000000d9',
                }}>
                Menu
              </div>
            }>
            {drawerItems.map((item, index) => {
              let inside:
                | string
                | JSX.Element = `${item.secondary}　${item.primary} `;
              if (item.route === '/publicchats' && cnt2 > 0) {
                inside = (
                  <Badge count={cnt2} offset={[0, 0]}>
                    {item.secondary}
                  </Badge>
                );
              } else if (item.route === '/chats' && cnt1 > 0) {
                inside = (
                  <Badge offset={[0, 0]}>
                    <span style={{ color: siderCollapsed ? '#fff' : '' }}>
                      {item.secondary}
                    </span>
                    <span style={{ ...countMessageStyle }}>
                      <span style={{ ...countNumberStyle }}>{cnt1}</span>
                    </span>
                  </Badge>
                );
              }
              const sliceItemRoute = item.route.slice(0, 6);
              const sliceChatIdOfReservations = chatIdOfReservations.slice(
                0,
                6,
              );
              return (
                <Menu.Item
                  style={
                    sliceItemRoute === sliceChatIdOfReservations
                      ? { backgroundColor: '#FFF0F0' }
                      : { backgroundColor: 'white' }
                  }
                  key={index}
                  onClick={() => history.push(item.route)}
                  icon={<item.icon />}>
                  {inside}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
          <Menu.ItemGroup
            key="g2"
            title={
              <div
                style={{
                  textAlign: siderCollapsed ? 'center' : 'left',
                  fontWeight: '500',
                  fontSize: '16px',
                  color: '#000000d9',
                }}>
                予約
              </div>
            }>
            <Menu.Item
              key={100}
              onClick={async () => history.push('/reservations')}
              style={
                chatIdOfReservations !== '/reservations'
                  ? { backgroundColor: 'white' }
                  : { backgroundColor: '#FFF0F0' }
              }>
              <Text>予約一覧</Text>
            </Menu.Item>
            <Menu.Item
              key={101}
              onClick={async () => history.push('/export-reservations')}
              style={
                chatIdOfReservations !== '/export-reservations'
                  ? { backgroundColor: 'white' }
                  : { backgroundColor: '#FFF0F0' }
              }>
              <Text>予約一覧（報告用Excel出力）</Text>
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      </Sider>
      <Layout
        style={{ height: '100%', minHeight: '100vh', backgroundColor: '#FFF' }}>
        <Header style={{ padding: 0, backgroundColor: '#FF979A' }}>
          <Row align="middle" style={{ alignItems: 'center' }}>
            <Button
              onClick={() => setSiderCollapsed(!siderCollapsed)}
              type="primary"
              size="middle"
              shape="circle"
              style={{ marginLeft: 15 }}>
              <Col flex="0">
                {siderCollapsed ? (
                  <MenuUnfoldOutlined style={{ color: 'white' }} />
                ) : (
                  <MenuFoldOutlined style={{ color: 'white' }} />
                )}
              </Col>
            </Button>
            <Col flex="1" style={{ textAlign: 'center' }}></Col>
            <Col flex="0">
              <Button
                type="primary"
                shape="round"
                onClick={signOutAdmin}
                style={{ marginRight: 15, marginBottom: 16 }}>
                Sign out
              </Button>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            height: 'max-content',
            display: 'block',
            flexGrow: '1 1 0',
          }}>
          <Card style={{ width: '100%', height: '100%' }}> {children}</Card>
        </Content>
        <Footer
          style={{
            padding: '10px',
            height: headerHeight,
            textAlign: 'center',
            backgroundColor: '#f4f4fa',
          }}>
          <Text>All Rights Reserved.</Text>
          <br />
          <Text>ShareBuggy©</Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

const countMessageStyle: any = {
  position: 'relative',
  display: 'inline-block',
  height: '22px',
  transition: 'none 0s ease 0s',
  marginLeft: '8px',
  color: '#fff',
};
const countNumberStyle: any = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '20',
  minWidth: '22px',
  height: '22px',
  color: '#fff',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  background: '#ff4d4f',
  borderRadius: '50%',
};
