/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkAdminNotSeenChatCount
// ====================================================

export interface checkAdminNotSeenChatCount_checkAdminNotSeenChatCount {
  private: number | null;
  public: number | null;
}

export interface checkAdminNotSeenChatCount {
  checkAdminNotSeenChatCount: checkAdminNotSeenChatCount_checkAdminNotSeenChatCount | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEND_MESSAGE_IMAGE
// ====================================================

export interface SEND_MESSAGE_IMAGE_sendMessage {
  id: string;
}

export interface SEND_MESSAGE_IMAGE {
  sendMessage: SEND_MESSAGE_IMAGE_sendMessage | null;
}

export interface SEND_MESSAGE_IMAGEVariables {
  chatId: string;
  text?: string | null;
  helpQuestion?: HelpQuestion | null;
  messageType: MessageType;
  imageKey?: string | null;
  imageBucket?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEND_PUBLIC_MESSAGE
// ====================================================

export interface SEND_PUBLIC_MESSAGE_sendPublicMessage {
  id: string;
}

export interface SEND_PUBLIC_MESSAGE {
  sendPublicMessage: SEND_PUBLIC_MESSAGE_sendPublicMessage;
}

export interface SEND_PUBLIC_MESSAGEVariables {
  email: string;
  text: string;
  helpQuestion?: HelpQuestion | null;
  messageType: MessageType;
  imageKey?: string | null;
  imageBucket?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFacilityImage
// ====================================================

export interface updateFacilityImage_updateFacility {
  id: string;
}

export interface updateFacilityImage {
  updateFacility: updateFacilityImage_updateFacility | null;
}

export interface updateFacilityImageVariables {
  facilityId: string;
  data?: FacilityUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePortImage
// ====================================================

export interface updatePortImage_updatePort {
  id: string;
}

export interface updatePortImage {
  updatePort: updatePortImage_updatePort | null;
}

export interface updatePortImageVariables {
  portId: string;
  data?: PortUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: babyCarsQuery
// ====================================================

export interface babyCarsQuery_babyCars_door_port {
  id: string;
  name: string;
}

export interface babyCarsQuery_babyCars_door {
  id: string;
  code: string;
  port: babyCarsQuery_babyCars_door_port | null;
}

export interface babyCarsQuery_babyCars {
  id: string;
  code: string;
  door: babyCarsQuery_babyCars_door | null;
  status: Status;
  cleaningStatus: CleaningStatus;
}

export interface babyCarsQuery {
  babyCars: babyCarsQuery_babyCars[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BABYCAR
// ====================================================

export interface BABYCAR_babyCarCleaned {
  id: string;
  code: string;
  status: Status;
  cleaningStatus: CleaningStatus;
}

export interface BABYCAR {
  babyCarCleaned: BABYCAR_babyCarCleaned | null;
}

export interface BABYCARVariables {
  babyCarId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_BabyCar
// ====================================================

export interface ADD_BabyCar_addBabyCar {
  id: string;
}

export interface ADD_BabyCar {
  addBabyCar: ADD_BabyCar_addBabyCar;
}

export interface ADD_BabyCarVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBabyCar
// ====================================================

export interface deleteBabyCar_deleteBabyCar {
  id: string;
}

export interface deleteBabyCar {
  deleteBabyCar: deleteBabyCar_deleteBabyCar;
}

export interface deleteBabyCarVariables {
  babyCarId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBabyCar
// ====================================================

export interface updateBabyCar_updateBabyCar {
  cleaningStatus: CleaningStatus;
}

export interface updateBabyCar {
  updateBabyCar: updateBabyCar_updateBabyCar | null;
}

export interface updateBabyCarVariables {
  babyCarId: string;
  data: BabyCarUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_QUERY1
// ====================================================

export interface CHAT_QUERY1_chats_messages_imageContent {
  imageKey: string | null;
  imageBucket: string | null;
}

export interface CHAT_QUERY1_chats_messages_user {
  id: string;
  firstname: string;
  lastname: string;
  profileImageUrl: string | null;
  role: Role | null;
}

export interface CHAT_QUERY1_chats_messages_chat {
  id: string;
  name: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface CHAT_QUERY1_chats_messages {
  id: string;
  imageContent: CHAT_QUERY1_chats_messages_imageContent | null;
  text: string | null;
  messageType: MessageType | null;
  createdAt: any;
  user: CHAT_QUERY1_chats_messages_user;
  chat: CHAT_QUERY1_chats_messages_chat;
}

export interface CHAT_QUERY1_chats {
  id: string;
  name: string;
  createdAt: any;
  messages: CHAT_QUERY1_chats_messages[];
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface CHAT_QUERY1 {
  chats: CHAT_QUERY1_chats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindChatsPage
// ====================================================

export interface FindChatsPage_chatsPage {
  id: string;
  name: string;
  createdAt: any;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
  newMessagesCount: number | null;
}

export interface FindChatsPage {
  chatsPage: FindChatsPage_chatsPage[];
}

export interface FindChatsPageVariables {
  text?: string | null;
  helpQuestion?: HelpQuestion | null;
  allMember?: boolean | null;
  limit: number;
  offset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindMessagesPage
// ====================================================

export interface FindMessagesPage_messagesPage_imageContent {
  imageKey: string | null;
  imageBucket: string | null;
}

export interface FindMessagesPage_messagesPage_user {
  id: string;
  firstname: string;
  lastname: string;
  profileImageUrl: string | null;
  role: Role | null;
}

export interface FindMessagesPage_messagesPage_chat {
  id: string;
  name: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface FindMessagesPage_messagesPage {
  id: string;
  imageContent: FindMessagesPage_messagesPage_imageContent | null;
  text: string | null;
  messageType: MessageType | null;
  createdAt: any;
  user: FindMessagesPage_messagesPage_user;
  chat: FindMessagesPage_messagesPage_chat;
}

export interface FindMessagesPage {
  messagesPage: FindMessagesPage_messagesPage[];
}

export interface FindMessagesPageVariables {
  text?: string | null;
  helpQuestion?: HelpQuestion | null;
  chatId: string;
  limit: number;
  offset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: CHAT_SUBSCRIPTION1
// ====================================================

export interface CHAT_SUBSCRIPTION1_chatSubscription_user {
  id: string;
  firstname: string;
  lastname: string;
  profileImageUrl: string | null;
  role: Role | null;
}

export interface CHAT_SUBSCRIPTION1_chatSubscription_chat {
  id: string;
  name: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
}

export interface CHAT_SUBSCRIPTION1_chatSubscription {
  id: string;
  text: string | null;
  createdAt: any;
  user: CHAT_SUBSCRIPTION1_chatSubscription_user;
  chat: CHAT_SUBSCRIPTION1_chatSubscription_chat;
}

export interface CHAT_SUBSCRIPTION1 {
  chatSubscription: CHAT_SUBSCRIPTION1_chatSubscription | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEEN_BY_ADMIN
// ====================================================

export interface SEEN_BY_ADMIN_seenByAdmin {
  id: string;
}

export interface SEEN_BY_ADMIN {
  seenByAdmin: SEEN_BY_ADMIN_seenByAdmin | null;
}

export interface SEEN_BY_ADMINVariables {
  chatId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEND_MESSAGE2
// ====================================================

export interface SEND_MESSAGE2_sendMessage {
  __typename: "Message";
  id: string;
}

export interface SEND_MESSAGE2 {
  sendMessage: SEND_MESSAGE2_sendMessage | null;
}

export interface SEND_MESSAGE2Variables {
  chatId: string;
  text?: string | null;
  messageType: MessageType;
  imageKey?: string | null;
  imageBucket?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ME
// ====================================================

export interface ME_me {
  id: string;
  email: string;
  profileImageUrl: string | null;
}

export interface ME {
  me: ME_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SEARCHMESSAGE
// ====================================================

export interface SEARCHMESSAGE_searchMessage_messages_imageContent {
  imageKey: string | null;
  imageBucket: string | null;
}

export interface SEARCHMESSAGE_searchMessage_messages_user {
  id: string;
  firstname: string;
  lastname: string;
  profileImageUrl: string | null;
  role: Role | null;
}

export interface SEARCHMESSAGE_searchMessage_messages_chat {
  id: string;
  name: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface SEARCHMESSAGE_searchMessage_messages {
  id: string;
  imageContent: SEARCHMESSAGE_searchMessage_messages_imageContent | null;
  text: string | null;
  messageType: MessageType | null;
  createdAt: any;
  user: SEARCHMESSAGE_searchMessage_messages_user;
  chat: SEARCHMESSAGE_searchMessage_messages_chat;
}

export interface SEARCHMESSAGE_searchMessage {
  id: string;
  name: string;
  createdAt: any;
  messages: SEARCHMESSAGE_searchMessage_messages[];
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface SEARCHMESSAGE {
  searchMessage: (SEARCHMESSAGE_searchMessage | null)[] | null;
}

export interface SEARCHMESSAGEVariables {
  text?: string | null;
  helpQuestion?: HelpQuestion | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: usersQuery
// ====================================================

export interface usersQuery_users_creditCard {
  id: string;
}

export interface usersQuery_users {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNo: string | null;
  creditCard: usersQuery_users_creditCard | null;
}

export interface usersQuery {
  users: usersQuery_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DASHBOARD_QUERY
// ====================================================

export interface DASHBOARD_QUERY_adminDashboard {
  customersNumber: number;
  portsNumber: number;
  reservationsNumber: number;
  babyCarsNumber: number;
}

export interface DASHBOARD_QUERY_ports {
  id: string;
  name: string;
  longitude: number;
  latitude: number;
  imageUrls: (string | null)[] | null;
  numberOfDoors: number;
  numberOfAvailableDoors: number;
  numberOfAvailableBabyCars: number;
}

export interface DASHBOARD_QUERY {
  adminDashboard: DASHBOARD_QUERY_adminDashboard | null;
  ports: DASHBOARD_QUERY_ports[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EMAILCONFIRM
// ====================================================

export interface EMAILCONFIRM_userConfirm {
  id: string;
}

export interface EMAILCONFIRM {
  userConfirm: EMAILCONFIRM_userConfirm;
}

export interface EMAILCONFIRMVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: facilitiesQuery
// ====================================================

export interface facilitiesQuery_facilities_weekTimes {
  id: string;
  weekday: string | null;
  time: string | null;
}

export interface facilitiesQuery_facilities {
  id: string;
  name: string;
  subName: string | null;
  category: string | null;
  description: string | null;
  zipCode: string | null;
  address: string;
  longitude: number;
  latitude: number;
  station: string;
  openYearRound: boolean | null;
  openingHourNote: string | null;
  imageUrl1: string | null;
  imageUrl2: string | null;
  imageUrl3: string | null;
  washBasin: boolean | null;
  milkWater: boolean | null;
  microwave: boolean | null;
  diapersPail: boolean | null;
  babyChair: boolean | null;
  weightScale: boolean | null;
  vendingMachine: boolean | null;
  privateRoom: boolean | null;
  menOK: boolean | null;
  facilityNote: string | null;
  weekTimes: (facilitiesQuery_facilities_weekTimes | null)[] | null;
}

export interface facilitiesQuery {
  facilities: facilitiesQuery_facilities[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addFacility
// ====================================================

export interface addFacility_addFacility {
  id: string;
}

export interface addFacility {
  addFacility: addFacility_addFacility;
}

export interface addFacilityVariables {
  data: AddFacilityInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteFacility
// ====================================================

export interface deleteFacility_deleteFacility {
  id: string;
}

export interface deleteFacility {
  deleteFacility: deleteFacility_deleteFacility;
}

export interface deleteFacilityVariables {
  facilityId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateFacility
// ====================================================

export interface updateFacility_updateFacility {
  id: string;
}

export interface updateFacility {
  updateFacility: updateFacility_updateFacility | null;
}

export interface updateFacilityVariables {
  facilityId: string;
  data?: FacilityUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LOGIN
// ====================================================

export interface LOGIN_adminLogin {
  token: string;
}

export interface LOGIN {
  adminLogin: LOGIN_adminLogin;
}

export interface LOGINVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PORTS
// ====================================================

export interface PORTS_ports_doors_babyCar {
  id: string;
  status: Status;
  code: string;
}

export interface PORTS_ports_doors {
  id: string;
  status: DoorStatus | null;
  doorIndex: number;
  babyCar: PORTS_ports_doors_babyCar | null;
}

export interface PORTS_ports {
  id: string;
  name: string;
  doors: PORTS_ports_doors[];
}

export interface PORTS {
  ports: PORTS_ports[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BABYCARS
// ====================================================

export interface BABYCARS_babyCars_door {
  id: string;
}

export interface BABYCARS_babyCars {
  id: string;
  status: Status;
  code: string;
  door: BABYCARS_babyCars_door | null;
}

export interface BABYCARS {
  babyCars: BABYCARS_babyCars[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TOGGLER
// ====================================================

export interface TOGGLER_toggleDoorStatus {
  id: string;
}

export interface TOGGLER {
  toggleDoorStatus: TOGGLER_toggleDoorStatus | null;
}

export interface TOGGLERVariables {
  doorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_MANAGER_BABYCAR
// ====================================================

export interface UPDATE_MANAGER_BABYCAR_updateManagerBabyCar {
  id: string;
}

export interface UPDATE_MANAGER_BABYCAR {
  updateManagerBabyCar: UPDATE_MANAGER_BABYCAR_updateManagerBabyCar | null;
}

export interface UPDATE_MANAGER_BABYCARVariables {
  babyCarId: string;
  doorId?: string | null;
  data: BabyCarUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: portsQuery
// ====================================================

export interface portsQuery_ports_doors_babyCar {
  id: string;
  code: string;
  status: Status;
  cleaningStatus: CleaningStatus;
}

export interface portsQuery_ports_doors {
  id: string;
  code: string;
  status: DoorStatus | null;
  isOpen: boolean | null;
  babyCar: portsQuery_ports_doors_babyCar | null;
}

export interface portsQuery_ports {
  id: string;
  name: string;
  numberOfDoors: number;
  rasPiId: string | null;
  address: string;
  zipCode: string | null;
  longitude: number;
  latitude: number;
  weekdayStart: any | null;
  weekdayEnd: any | null;
  weekendStart: any | null;
  weekendEnd: any | null;
  openingHourNote: string | null;
  description: string | null;
  note: string | null;
  imageUrl1: string | null;
  imageUrl2: string | null;
  imageUrl3: string | null;
  notCleanEmail: string | null;
  notificationEmail: string | null;
  doors: portsQuery_ports_doors[];
}

export interface portsQuery {
  ports: portsQuery_ports[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OPEN_DOOR
// ====================================================

export interface OPEN_DOOR {
  openDoorAdmin: boolean;
}

export interface OPEN_DOORVariables {
  doorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: DOOR_SUBSCRIPTION
// ====================================================

export interface DOOR_SUBSCRIPTION_doorSubscription {
  id: string;
  code: string;
  status: DoorStatus | null;
  isOpen: boolean | null;
}

export interface DOOR_SUBSCRIPTION {
  doorSubscription: DOOR_SUBSCRIPTION_doorSubscription | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_PORT
// ====================================================

export interface ADD_PORT_addPort {
  id: string;
}

export interface ADD_PORT {
  addPort: ADD_PORT_addPort;
}

export interface ADD_PORTVariables {
  data: AddPortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DELETE_DOOR
// ====================================================

export interface DELETE_DOOR_deleteDoor {
  id: string;
}

export interface DELETE_DOOR {
  deleteDoor: DELETE_DOOR_deleteDoor;
}

export interface DELETE_DOORVariables {
  doorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_DOOR
// ====================================================

export interface UPDATE_DOOR_updateDoor {
  code: string;
}

export interface UPDATE_DOOR {
  updateDoor: UPDATE_DOOR_updateDoor | null;
}

export interface UPDATE_DOORVariables {
  doorId: string;
  data?: DoorUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_DOOR_TO_PROT
// ====================================================

export interface ADD_DOOR_TO_PROT_addDoorToPort {
  id: string;
}

export interface ADD_DOOR_TO_PROT {
  addDoorToPort: ADD_DOOR_TO_PROT_addDoorToPort;
}

export interface ADD_DOOR_TO_PROTVariables {
  portId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePort
// ====================================================

export interface updatePort_updatePort {
  id: string;
}

export interface updatePort {
  updatePort: updatePort_updatePort | null;
}

export interface updatePortVariables {
  portId: string;
  data?: PortUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: checkRasPi
// ====================================================

export interface checkRasPi {
  checkRasPi: boolean | null;
}

export interface checkRasPiVariables {
  rasPiId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rebootRasPi
// ====================================================

export interface rebootRasPi {
  rebootRasPi: boolean | null;
}

export interface rebootRasPiVariables {
  rasPiId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: productsQuery
// ====================================================

export interface productsQuery_products {
  id: string;
  name: string;
  code: string;
  description: string | null;
  price: number;
  duration: number;
}

export interface productsQuery {
  products: productsQuery_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADD_PRODUCT
// ====================================================

export interface ADD_PRODUCT_addProduct {
  id: string;
}

export interface ADD_PRODUCT {
  addProduct: ADD_PRODUCT_addProduct;
}

export interface ADD_PRODUCTVariables {
  data: AddProductInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateProduct
// ====================================================

export interface updateProduct_updateProduct {
  id: string;
}

export interface updateProduct {
  updateProduct: updateProduct_updateProduct | null;
}

export interface updateProductVariables {
  productId: string;
  data?: ProductUpdateInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CHAT_QUERY3
// ====================================================

export interface CHAT_QUERY3_publicChats_messages_imageContent {
  imageKey: string | null;
  imageBucket: string | null;
}

export interface CHAT_QUERY3_publicChats_messages_publicChat {
  id: string;
  lastSeenByAdmin: any | null;
}

export interface CHAT_QUERY3_publicChats_messages {
  createdAt: any;
  id: string;
  imageContent: CHAT_QUERY3_publicChats_messages_imageContent | null;
  isAdminMessage: boolean | null;
  publicChat: CHAT_QUERY3_publicChats_messages_publicChat | null;
  text: string | null;
}

export interface CHAT_QUERY3_publicChats {
  id: string;
  email: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
  createdAt: any;
  messages: CHAT_QUERY3_publicChats_messages[];
}

export interface CHAT_QUERY3 {
  publicChats: CHAT_QUERY3_publicChats[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: CHAT_SUBSCRIPTION3
// ====================================================

export interface CHAT_SUBSCRIPTION3_publicChatsSubscription_publicChat {
  id: string;
  email: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
}

export interface CHAT_SUBSCRIPTION3_publicChatsSubscription {
  id: string;
  createdAt: any;
  text: string | null;
  isAdminMessage: boolean | null;
  publicChat: CHAT_SUBSCRIPTION3_publicChatsSubscription_publicChat | null;
}

export interface CHAT_SUBSCRIPTION3 {
  publicChatsSubscription: CHAT_SUBSCRIPTION3_publicChatsSubscription | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEND_MESSAGE3
// ====================================================

export interface SEND_MESSAGE3_sendPublicMessage {
  id: string;
}

export interface SEND_MESSAGE3 {
  sendPublicMessage: SEND_MESSAGE3_sendPublicMessage;
}

export interface SEND_MESSAGE3Variables {
  email: string;
  text?: string | null;
  messageType: MessageType;
  imageKey?: string | null;
  imageBucket?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SEEN_BY_ADMIN_PUBLIC_CHAT
// ====================================================

export interface SEEN_BY_ADMIN_PUBLIC_CHAT_seenByAdminPublicChat {
  id: string;
}

export interface SEEN_BY_ADMIN_PUBLIC_CHAT {
  seenByAdminPublicChat: SEEN_BY_ADMIN_PUBLIC_CHAT_seenByAdminPublicChat | null;
}

export interface SEEN_BY_ADMIN_PUBLIC_CHATVariables {
  publicChatId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SEARCHPUBLICMESSAGES
// ====================================================

export interface SEARCHPUBLICMESSAGES_searchPublicMessages_messages_imageContent {
  imageKey: string | null;
  imageBucket: string | null;
}

export interface SEARCHPUBLICMESSAGES_searchPublicMessages_messages_publicChat {
  id: string;
  lastSeenByAdmin: any | null;
}

export interface SEARCHPUBLICMESSAGES_searchPublicMessages_messages {
  createdAt: any;
  id: string;
  imageContent: SEARCHPUBLICMESSAGES_searchPublicMessages_messages_imageContent | null;
  isAdminMessage: boolean | null;
  publicChat: SEARCHPUBLICMESSAGES_searchPublicMessages_messages_publicChat | null;
  text: string | null;
}

export interface SEARCHPUBLICMESSAGES_searchPublicMessages {
  id: string;
  email: string;
  lastSeenByAdmin: any | null;
  lastSeenByUser: any | null;
  latestMessageTime: any | null;
  createdAt: any;
  messages: SEARCHPUBLICMESSAGES_searchPublicMessages_messages[];
}

export interface SEARCHPUBLICMESSAGES {
  searchPublicMessages: (SEARCHPUBLICMESSAGES_searchPublicMessages | null)[] | null;
}

export interface SEARCHPUBLICMESSAGESVariables {
  text?: string | null;
  helpQuestion?: HelpQuestion | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RESERVATIONS_CONNECTION
// ====================================================

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_user_chats {
  id: string;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_user {
  id: string;
  firstname: string;
  lastname: string;
  phoneNo: string | null;
  deletedAt: any | null;
  chats: RESERVATIONS_CONNECTION_reservationsConnection_item_user_chats[];
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_startPort {
  id: string;
  name: string;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_port {
  id: string;
  name: string;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_door {
  id: string;
  doorIndex: number;
  status: DoorStatus | null;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_babyCar {
  id: string;
  code: string;
  status: Status;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_issuedCoupon_coupon {
  id: string;
  discountAmount: number;
  name: string;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_issuedCoupon {
  id: string;
  createdAt: any;
  coupon: RESERVATIONS_CONNECTION_reservationsConnection_item_issuedCoupon_coupon;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_startDoor {
  id: string;
  doorIndex: number;
  status: DoorStatus | null;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item_product {
  name: string;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection_item {
  id: string;
  startTime: any | null;
  endTime: any | null;
  returnTime: any | null;
  token: string;
  status: boolean | null;
  paymentStatus: boolean;
  user: RESERVATIONS_CONNECTION_reservationsConnection_item_user;
  startPort: RESERVATIONS_CONNECTION_reservationsConnection_item_startPort | null;
  port: RESERVATIONS_CONNECTION_reservationsConnection_item_port;
  door: RESERVATIONS_CONNECTION_reservationsConnection_item_door;
  babyCar: RESERVATIONS_CONNECTION_reservationsConnection_item_babyCar;
  taxedAmount: number | null;
  issuedCoupon: RESERVATIONS_CONNECTION_reservationsConnection_item_issuedCoupon | null;
  startDoor: RESERVATIONS_CONNECTION_reservationsConnection_item_startDoor | null;
  product: RESERVATIONS_CONNECTION_reservationsConnection_item_product;
  extendedTimes: number | null;
}

export interface RESERVATIONS_CONNECTION_reservationsConnection {
  item: RESERVATIONS_CONNECTION_reservationsConnection_item[];
  total: number | null;
}

export interface RESERVATIONS_CONNECTION {
  reservationsConnection: RESERVATIONS_CONNECTION_reservationsConnection | null;
}

export interface RESERVATIONS_CONNECTIONVariables {
  first?: number | null;
  skip?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UNLOCK
// ====================================================

export interface UNLOCK {
  unlock: boolean;
}

export interface UNLOCKVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADMINLOCK
// ====================================================

export interface ADMINLOCK {
  adminLock: boolean;
}

export interface ADMINLOCKVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FINISH
// ====================================================

export interface FINISH_finishReservation {
  id: string;
}

export interface FINISH {
  finishReservation: FINISH_finishReservation | null;
}

export interface FINISHVariables {
  reservationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ADMIN_LOCK_RESERVATION
// ====================================================

export interface ADMIN_LOCK_RESERVATION_adminLockReservation {
  id: string;
  token: string;
}

export interface ADMIN_LOCK_RESERVATION {
  adminLockReservation: ADMIN_LOCK_RESERVATION_adminLockReservation;
}

export interface ADMIN_LOCK_RESERVATIONVariables {
  portId: string;
  doorId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UPDATE_RESERVATION
// ====================================================

export interface UPDATE_RESERVATION_updateReservation {
  paymentStatus: boolean;
}

export interface UPDATE_RESERVATION {
  updateReservation: UPDATE_RESERVATION_updateReservation | null;
}

export interface UPDATE_RESERVATIONVariables {
  reservationId: string;
  data: ReservationUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CHANGEPASSWORDRESET
// ====================================================

export interface CHANGEPASSWORDRESET {
  changePasswordReset: boolean;
}

export interface CHANGEPASSWORDRESETVariables {
  resetPasswordNumber: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CleaningStatus {
  NOT_RENTABLE = "NOT_RENTABLE",
  NOT_USED = "NOT_USED",
  USED = "USED",
}

export enum DoorStatus {
  EMPTY = "EMPTY",
  FULL = "FULL",
}

export enum HelpQuestion {
  BABYCAR_BROKE = "BABYCAR_BROKE",
  BOX_TROUBLE = "BOX_TROUBLE",
  LOST_BABYCAR = "LOST_BABYCAR",
  OHTER = "OHTER",
  SETTLEMENT_TROUBLE = "SETTLEMENT_TROUBLE",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum MessageType {
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export enum Role {
  CUSTOMER = "CUSTOMER",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

export enum Status {
  INSIDE = "INSIDE",
  OUTSIDE = "OUTSIDE",
  SPARE = "SPARE",
}

export interface AddFacilityInput {
  name: string;
  subName?: string | null;
  category?: string | null;
  description?: string | null;
  zipCode?: string | null;
  address: string;
  longitude: number;
  latitude: number;
  station: string;
  openYearRound?: boolean | null;
  weekTimes?: (AddWeekTimeInput | null)[] | null;
  openingHourNote?: string | null;
  imageUrls?: (string | null)[] | null;
  imageUrl1?: string | null;
  imageUrl2?: string | null;
  imageUrl3?: string | null;
  washBasin?: boolean | null;
  milkWater?: boolean | null;
  microwave?: boolean | null;
  diapersPail?: boolean | null;
  babyChair?: boolean | null;
  weightScale?: boolean | null;
  vendingMachine?: boolean | null;
  privateRoom?: boolean | null;
  menOK?: boolean | null;
  facilityNote?: string | null;
}

export interface AddPortInput {
  rasPiId: string;
  name: string;
  address: string;
  weekdayStart?: any | null;
  weekdayEnd?: any | null;
  weekendStart?: any | null;
  weekendEnd?: any | null;
  openingHourNote?: string | null;
  note?: string | null;
  description?: string | null;
  zipCode?: string | null;
  imageUrls?: (string | null)[] | null;
  imageUrl1?: string | null;
  imageUrl2?: string | null;
  imageUrl3?: string | null;
  numberOfDoors: number;
  longitude: number;
  latitude: number;
  notCleanEmail?: string | null;
  notificationEmail?: string | null;
  discountPercent?: number | null;
  discountedStartDate?: string | null;
}

export interface AddProductInput {
  name: string;
  code: string;
  price: number;
  duration: number;
  description?: string | null;
}

export interface AddWeekTimeInput {
  weekday: string;
  time: string;
}

export interface BabyCarUpdateInput {
  id?: string | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  code?: string | null;
  status?: Status | null;
  cleaningStatus?: CleaningStatus | null;
}

export interface DoorUpdateInput {
  id?: string | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  deletedAt?: any | null;
  status?: Status | null;
  code?: string | null;
  doorIndex?: number | null;
  isOpen?: boolean | null;
}

export interface FacilityUpdateInput {
  deletedAt?: any | null;
  name?: string | null;
  subName?: string | null;
  category?: string | null;
  description?: string | null;
  zipCode?: string | null;
  address?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  station?: string | null;
  openYearRound?: boolean | null;
  weekTimes?: (WeekTimeUpdateInput | null)[] | null;
  openingHourNote?: string | null;
  imageUrls?: (string | null)[] | null;
  imageUrl1?: string | null;
  imageUrl2?: string | null;
  imageUrl3?: string | null;
  washBasin?: boolean | null;
  milkWater?: boolean | null;
  microwave?: boolean | null;
  diapersPail?: boolean | null;
  babyChair?: boolean | null;
  weightScale?: boolean | null;
  vendingMachine?: boolean | null;
  privateRoom?: boolean | null;
  menOK?: boolean | null;
  facilityNote?: string | null;
}

export interface PortUpdateInput {
  rasPiId?: string | null;
  name?: string | null;
  address?: string | null;
  weekdayStart?: any | null;
  weekdayEnd?: any | null;
  weekendStart?: any | null;
  weekendEnd?: any | null;
  openingHourNote?: string | null;
  note?: string | null;
  description?: string | null;
  zipCode?: string | null;
  imageUrls?: (string | null)[] | null;
  imageUrl1?: string | null;
  imageUrl2?: string | null;
  imageUrl3?: string | null;
  numberOfDoors?: number | null;
  longitude?: number | null;
  latitude?: number | null;
  notCleanEmail?: string | null;
  notificationEmail?: string | null;
}

export interface ProductUpdateInput {
  name?: string | null;
  code?: string | null;
  price?: number | null;
  duration?: number | null;
  description?: string | null;
}

export interface ReservationUpdateInput {
  deletedAt?: any | null;
  token?: string | null;
  status?: boolean | null;
  startTime?: any | null;
  endTime?: any | null;
  returnTime?: any | null;
  taxedAmount?: number | null;
  tax?: number | null;
  paymentStatus?: boolean | null;
  isExtended?: boolean | null;
  stripeChargeId?: string | null;
  couponReduction?: number | null;
  originalTaxedAmount?: number | null;
  extendedTimes?: number | null;
}

export interface WeekTimeUpdateInput {
  deletedAt?: any | null;
  weekday?: string | null;
  time?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
