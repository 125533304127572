import {useMutation, useQuery, useLazyQuery} from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {MySnackBar} from '@src/components/MySnackBar';
import {ReservationsComponent1} from '@src/components/Reservations/component';
import gql from 'graphql-tag';
import {useCallback, useState, useEffect, useMemo} from 'react';
import moment from 'moment/moment';
import {RESERVATIONS_CONNECTION} from "@src/generated/graphql";
import {useLocation} from 'react-router-dom';

const portsQuery = gql`
  query portsQuery {
    ports {
      id
      name
    }
  }
`;

const reservationsQuery = gql`
  query RESERVATIONS_CONNECTION($first: Int, $skip: Int, $email: String, $name: String, $startTimeFrom: DateTime, $startTimeTo: DateTime, $endTimeFrom: DateTime, $endTimeTo: DateTime, $phoneNo: String, $portBorrow: ID, $portReturn: ID, $status: Boolean) {
    reservationsConnection(first: $first, skip: $skip, email: $email, name: $name, startTimeFrom: $startTimeFrom, startTimeTo: $startTimeTo, endTimeFrom: $endTimeFrom, endTimeTo: $endTimeTo, phoneNo: $phoneNo, portBorrow: $portBorrow, portReturn: $portReturn, status: $status) {
      item {
        id
        startTime
        endTime
        returnTime
        token
        status
        paymentStatus
        user {
          id
          firstname
          lastname
          phoneNo
          deletedAt
          chats {
            id
          }
        }
        startPort {
          id
          name
        }
        port {
          id
          name
        }
        door {
          id
          doorIndex
          status
        }
        babyCar {
          id
          code
          status
        }
        taxedAmount
        issuedCoupon {
          id
          createdAt
          coupon {
            id
            discountAmount
            name
          }
        }
        startDoor {
          id
          doorIndex
          status
        }
        product {
          name
        }
        extendedTimes
        paymentHistories {
          status
        }
      }
      total
    }
  }
`;

const UNLOCK = gql`
  mutation UNLOCK($token: String!) {
    unlock(token: $token)
  }
`;

const ADMINLOCK = gql`
  mutation ADMINLOCK($token: String!) {
    adminLock(token: $token)
  }
`;

const FINISH = gql`
  mutation FINISH($reservationId: ID!) {
    finishReservation(reservationId: $reservationId) {
      id
    }
  }
`;

const ADMIN_LOCK_RESERVATION = gql`
  mutation ADMIN_LOCK_RESERVATION($portId: ID!, $doorId: ID!) {
    adminLockReservation(portId: $portId, doorId: $doorId) {
      id
      token
    }
  }
`;

const UPDATE_RESERVATION = gql`
  mutation UPDATE_RESERVATION($reservationId: ID!, $data: ReservationUpdateInput!) {
    updateReservation(reservationId: $reservationId, data: $data) {
      paymentStatus
    }
  }
`;

const CREATE_CHAT = gql`
  mutation CREATE_CHAT($userId: ID!) {
    createChat(userId: $userId) {
      id
      createdAt
      updatedAt
      name
      lastSeenByAdmin
      lastSeenByUser
      latestMessageTime
    }
  }
`;

export const ReservationsComponent = (props) => {
  const initialDate = moment().toDate();
  const location = useLocation();
  const emailFromCustomer = location?.state?.email;
  const startTimeFromCustomer = location?.state?.startTimeFrom;
  const endTimeFromCustomer = location?.state?.endTimeFrom;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [startTimeFrom, setStartTimeFrom] = useState(startTimeFromCustomer ?? initialDate);
  const [startTimeTo, setStartTimeTo] = useState(initialDate);
  const [endTimeFrom, setEndTimeFrom] = useState(endTimeFromCustomer ?? initialDate);
  const [endTimeTo, setEndTimeTo] = useState(initialDate);
  const [status, setStatus] = useState('no-selection');
  const [portBorrow, setPortBorrow] = useState('no-selection');
  const [portReturn, setPortReturn] = useState('no-selection');
  const [email, setEmail] = useState(emailFromCustomer ?? '');
  const [searchStatus, setSearchStatus] = useState('**未検索状態です**');
  const [isSearchClick, setIsSearchClick] = useState(false);
  const [lastSuccessfulData, setLastSuccessfulData] = useState(null);

  const [reservationsRefetch, {
    loading: reservationsLoading,
    error: reservationsError,
    data: reservationsData
  }] = useLazyQuery<RESERVATIONS_CONNECTION>(reservationsQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: any) => {
        setLastSuccessfulData(data);
      }
    });

  const {data: portData, loading: portLoading} = useQuery(portsQuery, {
    fetchPolicy: 'network-only',
  });

  const [unlockMutation] = useMutation(UNLOCK);
  const [adminLockMutation] = useMutation(ADMINLOCK);
  const [finishMutation] = useMutation(FINISH);
  const [adminLockReservationMutation] = useMutation(ADMIN_LOCK_RESERVATION);
  const [updateReservationMutation] = useMutation(UPDATE_RESERVATION);
  const [createChatMutation] = useMutation(CREATE_CHAT);

  useEffect(() => {
    void reservationsRefetch({
      variables: {
        ...getVariables,
        startTimeFrom: null,
        startTimeTo: null,
        endTimeFrom: null,
        endTimeTo: null,
      }
    })
  }, []);

  const getVariables = useMemo(() => ({
    first: rowsPerPage,
    skip: page * rowsPerPage,
    name: name || null,
    phoneNo: phoneNo || null,
    startTimeFrom: startTimeFrom || null,
    startTimeTo: startTimeTo || null,
    endTimeFrom: endTimeFrom || null,
    endTimeTo: endTimeTo || null,
    status: status === 'no-selection' ? null : !!+status,
    portBorrow: portBorrow === 'no-selection' ? null : portBorrow,
    portReturn: portReturn === 'no-selection' ? null : portReturn,
    email: email || null,
  }), [rowsPerPage, page, name, phoneNo, startTimeFrom, startTimeTo, endTimeFrom, endTimeTo, status, portBorrow, portReturn, email]);

  const onChangePage = useCallback((_, pageNumber) => {
    setPage(pageNumber);
    void reservationsRefetch({
      variables: {
        ...getVariables,
        skip: pageNumber * (rowsPerPage || 5),
        startTimeFrom: isSearchClick ? startTimeFrom : null,
        startTimeTo: isSearchClick ? startTimeTo : null,
        endTimeFrom: isSearchClick ? endTimeFrom : null,
        endTimeTo: isSearchClick ? endTimeTo : null
      }
    });
  }, [getVariables, rowsPerPage, reservationsRefetch]);

  const onChangeRowsPerPage = useCallback((event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    void reservationsRefetch({
      variables: {
        ...getVariables,
        first: newRowsPerPage,
        startTimeFrom: isSearchClick ? startTimeFrom : null,
        startTimeTo: isSearchClick ? startTimeTo : null,
        endTimeFrom: isSearchClick ? endTimeFrom : null,
        endTimeTo: isSearchClick ? endTimeTo : null,
      }
    });
  }, [getVariables, reservationsRefetch]);

  const unlock = useCallback(async (token) => {
    if (confirm('ユーザー側画面でも受取完了となり開錠ができなくなりますがよろしいですか？')) {
      try {
        await unlockMutation({variables: {token}});
        void reservationsRefetch({
          variables: {
            ...getVariables,
            startTimeFrom: isSearchClick ? startTimeFrom : null,
            startTimeTo: isSearchClick ? startTimeTo : null,
            endTimeFrom: isSearchClick ? endTimeFrom : null,
            endTimeTo: isSearchClick ? endTimeTo : null,
          }
        });
        props.showAlert("Successfully changed the reservation's status", 'success');
      } catch (error) {
        props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
      }
    }
  }, [getVariables, reservationsRefetch, unlockMutation, props.showAlert]);

  const adminLock = useCallback(async (token) => {
    try {
      await adminLockMutation({variables: {token}});
      if (confirm('ドア状態がopenもしくはcloseか必ずご確認ください')) {
        void reservationsRefetch({
          variables: {
            ...getVariables,
            startTimeFrom: isSearchClick ? startTimeFrom : null,
            startTimeTo: isSearchClick ? startTimeTo : null,
            endTimeFrom: isSearchClick ? endTimeFrom : null,
            endTimeTo: isSearchClick ? endTimeTo : null,
          }
        });
        props.showAlert("Successfully changed the reservation's status", 'success');
      }
    } catch (error) {
      props.showAlert(error.message.replace('GraphQL error: ', ''), 'error');
    }
  }, [getVariables, reservationsRefetch, adminLockMutation, props.showAlert]);

  const adminLockReservation = useCallback(async (portId, doorId) => {
    if (confirm('ユーザー側画面でも返却開始となりますがよろしいですか？')) {
      await adminLockReservationMutation({variables: {portId, doorId}});
      void reservationsRefetch({
        variables: {
          ...getVariables,
          startTimeFrom: isSearchClick ? startTimeFrom : null,
          startTimeTo: isSearchClick ? startTimeTo : null,
          endTimeFrom: isSearchClick ? endTimeFrom : null,
          endTimeTo: isSearchClick ? endTimeTo : null,
        }
      });
    }
  }, [getVariables, reservationsRefetch, adminLockReservationMutation]);

  const updateReservation = useCallback(async (reservationId, paymentStatus) => {
    if (confirm('支払い状況を変更してもよろしいですか？')) {
      await updateReservationMutation({variables: {reservationId, data: {paymentStatus}}});
      void reservationsRefetch({
        variables: {
          ...getVariables,
          startTimeFrom: isSearchClick ? startTimeFrom : null,
          startTimeTo: isSearchClick ? startTimeTo : null,
          endTimeFrom: isSearchClick ? endTimeFrom : null,
          endTimeTo: isSearchClick ? endTimeTo : null,
        }
      });
    }
  }, [getVariables, reservationsRefetch, updateReservationMutation]);

  const finish = useCallback(async (reservationId) => {
    await finishMutation({variables: {reservationId}});
    void reservationsRefetch({
      variables: {
        ...getVariables,
        startTimeFrom: isSearchClick ? startTimeFrom : null,
        startTimeTo: isSearchClick ? startTimeTo : null,
        endTimeFrom: isSearchClick ? endTimeFrom : null,
        endTimeTo: isSearchClick ? endTimeTo : null,
      }
    });
  }, [getVariables, reservationsRefetch, finishMutation]);

  const createChat = useCallback(async (userId) => {
    return await createChatMutation({variables: {userId}});
  }, [createChatMutation]);

  const filterData = useCallback(() => {
    setSearchStatus('');
    setIsSearchClick(true);
    void reservationsRefetch({variables: {...getVariables}});
  }, [getVariables, reservationsRefetch]);

  const allClear = useCallback(() => {
    setIsSearchClick(false);
    setSearchStatus('**未検索状態です**');
    setName('');
    setPhoneNo('');
    setStartTimeFrom(initialDate);
    setStartTimeTo(initialDate);
    setEndTimeFrom(initialDate);
    setEndTimeTo(initialDate);
    setStatus('no-selection');
    setPortBorrow('no-selection');
    setPortReturn('no-selection');
    setEmail('');
    void reservationsRefetch({
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
        name: null,
        phoneNo: null,
        startTimeFrom: null,
        startTimeTo: null,
        endTimeFrom: null,
        endTimeTo: null,
        status: null,
        portBorrow: null,
        portReturn: null,
        email: null,
      }
    });
  }, [initialDate, page, rowsPerPage, reservationsRefetch]);

  const onChangeHandler = useCallback((e) => {
    const {name, value} = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'phoneNo':
        const numericValue = value.replace(/[^0-9]/g, '');
        setPhoneNo(numericValue);
        break;
      case 'startTimeFrom':
        setStartTimeFrom(value);
        break;
      case 'startTimeTo':
        setStartTimeTo(value);
        break;
      case 'endTimeFrom':
        setEndTimeFrom(value);
        break;
      case 'endTimeTo':
        setEndTimeTo(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'portBorrow':
        setPortBorrow(value);
        break;
      case 'portReturn':
        setPortReturn(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  }, []);
  const displayData = reservationsData || lastSuccessfulData;
  return (
    <>
      {(reservationsLoading || portLoading) && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '92dvh',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
        }}>
          <CircularProgress/>
        </div>
      )}
      {
        reservationsError && (
          <MySnackBar variant="error" message={reservationsError.graphQLErrors[0].message}/>
        )
      }
      <ReservationsComponent1
        portData={portData}
        filterData={filterData}
        reservationsData={displayData}
        unlock={unlock}
        adminLock={adminLock}
        updateReservation={updateReservation}
        finish={finish}
        adminLockReservation={adminLockReservation}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        isSystemAdmin={props.isSystemAdmin}
        createChat={createChat}
        searchState={{
          name,
          phoneNo,
          startTimeFrom,
          startTimeTo,
          endTimeFrom,
          endTimeTo,
          status,
          portBorrow,
          portReturn,
          email
        }}
        reservationsLoading={reservationsLoading}
        onChangeHandler={onChangeHandler}
        allClear={allClear}
        searchStatus={searchStatus}
      />
    </>
  );
};