import {Button, Table} from 'antd';
import {useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {TextField} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Search as SearchIcon} from '@material-ui/icons';
import {useLocation, useHistory} from 'react-router-dom';
import moment from 'moment/moment';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  toolbar: {
    display: 'flex',
  },
  contentPanel: {
    flex: '1 1 100%',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    maxWidth: 300,
  },
  iconButton: {
    padding: 10,
  },
}));

export type CustomerRowUserType = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phoneNo: string;
  creditCard?: Record<string, string> | null;
};
export type CustomerListType = {
  users: CustomerRowUserType[];
  resendVerifiedEmail: (email: string) => void;
};
export const CustomersComponent = ({
                                     users = [],
                                     resendVerifiedEmail,
                                   }: CustomerListType): JSX.Element => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useState<any>({
    name: '',
    email: '',
    phone: '',
  });
  const oneYearAgo = moment().subtract(1, 'years').toDate();
  const history = useHistory();
  const location = useLocation();
  let name = location?.state?.params;
  const emailUser = location.state?.email;

  const handleChange = (event) => {
    const {target} = event;
    searchParams[target.name] = target.value;
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (name || emailUser) {
      history.replace({pathname: location.pathname, state: null});
    }

    setSearchParams({...searchParams});
  };

  const filterUser = (users: CustomerRowUserType[] = []) => {
    if (name && emailUser) {
      setSearchParams({
        ...searchParams,
        name: name,
        email: emailUser,
      });
    } else if (name) {
      setSearchParams({
        ...searchParams,
        name: name,
      });
    } else if (emailUser) {
      setSearchParams({
        ...searchParams,
        name: name,
      });
    }

    let nameFilter = searchParams.name;
    let emailFilter = searchParams.email;
    if (name) {
      nameFilter = name;
    }

    if (emailFilter) {
      emailFilter = emailUser;
    }

    return users.filter(
      (val) =>
        `${val.firstname || ''}${val.lastname || ''}`?.includes(nameFilter) &&
        val.email?.includes(emailFilter) &&
        val.phoneNo?.includes(searchParams.phone),
    );
  };

  const columns = [
    {
      title: '名前',
      key: 'firstname',
      render: (_, user) => {
        return <div>{`${user.firstname || ''}${user.lastname || ''}`}</div>;
      },
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '電話番号',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'クレジットカード登録状態',
      dataIndex: 'creditCard',
      key: 'creditCard',
      render: (creditCard) => {
        return creditCard ? '登録済' : '未登録';
      },
    },
    {
      title: '開封状態 ',
      // dataIndex: 'creditCard',
      key: 'openedCondition',
      render: (record: any) => {
        return !record.emailConfirmationNumber
          ? '確認済み'
          : '検証されていない';
      },
    },
    {
      title: '認証メール再送',
      key: 'action',
      render: (record: any) => {
        return (
          <Button
            type="primary"
            disabled={!record.emailConfirmationNumber}
            onClick={() => resendVerifiedEmail(record.email)}>
            再送
          </Button>
        );
      },
    },
    {
      title: '予約一覧',
      key: 'action',
      render: (record: any) => {
        return (
          <Button
            onClick={() => {
              history.push(`/reservations`, {startTimeFrom: oneYearAgo, endTimeFrom: oneYearAgo, email: record?.email});
            }}>
            予約一覧
          </Button>
        );
      },
    },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.contentPanel}>
          <form className={classes.form} onSubmit={handleSearch}>
            <FormControl className={classes.input}>
              <TextField
                name="name"
                label="名前"
                variant="outlined"
                defaultValue={name ? name : ''}
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                name="email"
                label="メールアドレス"
                variant="outlined"
                defaultValue={emailUser ? emailUser : ''}
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.input}>
              <TextField
                name="phone"
                label="電話番号"
                variant="outlined"
                InputLabelProps={{shrink: true}}
                onChange={handleChange}
              />
            </FormControl>
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search">
              <SearchIcon/>
            </IconButton>
          </form>
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        dataSource={useMemo(
          () => filterUser(users),
          [users, searchParams.name, searchParams.email, searchParams.phoneNo],
        )}
        columns={columns}
      />
    </div>
  );
};
// export const CustomersComponent1 = ({
//   users = [],
// }: CustomerListType): JSX.Element => (
//   <div style={{ width: '100%', height: '100%' }}>
//     <h1>ユーザー</h1>
//     <Paper elevation={2}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>名前</TableCell>
//             <TableCell>メールアドレス</TableCell>
//             <TableCell>電話番号</TableCell>
//             <TableCell>クレジットカード登録状態</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {users.map((user) => (
//             <CustomerRow user={user} key={user.id} />
//           ))}
//         </TableBody>
//       </Table>
//     </Paper>
//   </div>
// );
