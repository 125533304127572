import Snackbar from '@material-ui/core/Snackbar';
import { MySnackbarContentWrapper } from '@src/components/SnackBarWrapper';
import { BabyCarsComponent } from '@src/pages/BabyCars';
import { Chats } from '@src/pages/Chats';
import { Customers } from '@src/pages/Customers';
import { DashBoardComponent } from '@src/pages/Dashboard';
import { EmailConfirmationComponent } from '@src/pages/EmailConfirmation';
import { Facilities } from '@src/pages/Facilities';
import { Home } from '@src/pages/Home';
import { LoginComponent } from '@src/pages/Login';
import { ManagerComponent } from '@src/pages/Manager';
import { PortsComponent1 } from '@src/pages/Ports';
import { ProductsComponent } from '@src/pages/Products';
import { PublicChat } from '@src/pages/PublicChats';
import { ReservationsComponent } from '@src/pages/Reservations';
import { ResetPasswordComponent } from '@src/pages/ResetPassword';
import { isTokenExpired } from '@src/utils';
import { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ExportReservationsComponent } from '@src/pages/ExportReservation';
import { Coupons } from '@src/pages/Coupons';
import { Banners } from '@src/pages/Banners';
import {Videos} from "@src/pages/Videos";

const ProtectedRoute = ({
  component: Component1,
  isModalOpen,
  token,
  isSystemAdmin,
  open,
  message,
  variant,
  handleClose,
  showAlert,
  ...rest
}: any) => {
  return token ? (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <Home {...matchProps}>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}>
              <MySnackbarContentWrapper
                onClose={handleClose}
                variant={variant}
                message={message}
              />
            </Snackbar>
            <Component1
              {...matchProps}
              isSystemAdmin={isSystemAdmin}
              isModalOpen={isModalOpen}
              showAlert={showAlert}
            />
          </Home>
        );
      }}
    />
  ) : (
    <Redirect to="/login" {...rest} />
  );
};

export class Router extends Component<any, any> {
  static whyDidYouRender: true;

  state: any = {
    token: this.props.token,
    open: false,
    variant: null,
    message: null,
  };

  // verify localStorage check
  componentDidMount() {
    this.bootStrapData();
  }

  bootStrapData = () => {
    try {
      const isSystemAdmin = localStorage.getItem('admin') === 'admin';
      this.setState({ isSystemAdmin });

      const token = localStorage.getItem('token');
      if (token !== null && token !== undefined) {
        const expired = isTokenExpired(token);
        if (!expired) {
          this.setState({ token });
        } else {
          localStorage.removeItem('token');
          this.setState({ token: null });
        }
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  refreshTokenFn = (data: any = {}) => {
    const { token } = data;
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }

    this.setState({
      token: data.token,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showAlert = (message, variant) => {
    this.setState({ open: true, message, variant });
  };

  renderRoute() {
    const protectedProps = {
      token: this.state.token,
      isSystemAdmin: this.state.isSystemAdmin,
      open: this.state.open,
      message: this.state.message,
      variant: this.state.variant,
      showAlert: this.showAlert,
      handleClose: this.handleClose,
    };

    return (
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={DashBoardComponent}
          {...protectedProps}
        />
        <Route
          path="/login"
          component={(props) => (
            <LoginComponent {...props} refreshTokenFn={this.refreshTokenFn} />
          )}
        />
        <Route
          path="/emailconfirmation"
          component={EmailConfirmationComponent}
        />
        <Route path="/resetpassword" component={ResetPasswordComponent} />
        <ProtectedRoute
          path="/babycars"
          component={BabyCarsComponent}
          {...protectedProps}
        />
        <ProtectedRoute
          exact
          path="/ports"
          component={PortsComponent1}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/ports/add"
          component={PortsComponent1}
          isModalOpen
          {...protectedProps}
        />
        <ProtectedRoute
          exact
          path="/facilities"
          component={Facilities}
          {...protectedProps}
        />
        <ProtectedRoute path="/chats" component={Chats} {...protectedProps} />
        <ProtectedRoute
          path="/publicchats"
          component={PublicChat}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/customers"
          component={Customers}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/reservations"
          component={ReservationsComponent}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/products"
          component={ProductsComponent}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/manager"
          component={ManagerComponent}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/coupons"
          component={Coupons}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/banners"
          component={Banners}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/videos"
          component={Videos}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/test"
          component={ManagerComponent}
          {...protectedProps}
        />
        <ProtectedRoute
          path="/export-reservations"
          component={ExportReservationsComponent}
          {...protectedProps}
        />
      </Switch>
    );
  }

  render() {
    return <BrowserRouter>{this.renderRoute()}</BrowserRouter>;
  }
}
