import {makeStyles} from "@material-ui/core/styles";
import {Form, Input, Table, Input as AntdInput} from "antd";
import Button from "@material-ui/core/Button";
import {PlusOutlined, CopyOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import VideoModal from "@src/components/Videos/VideoModal";
import VideoPreview from "@src/components/Videos/VideoPreview";

const {
  REACT_APP_AWS_PUBLIC_BUCKET_VIDEO,
} = process.env;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },

  buttonAdd: {
    borderTop: '1px solid #f0f0f0',
    borderLeft: '1px solid #f0f0f0',
    borderRight: '1px solid #f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '12px'
  },
  textarea: {
    width: '500px',
    minHeight: '100px !important',
    padding: '8px 44px 8px 8px',
    fontSize: '16px',
    border: '2px solid #202020',
    borderRadius: '4px',
    resize: 'vertical',
    color: '#202020',
    fontWeight: 500
  },
  textareaWrapper: {
    position: 'relative',
    width: 'max-content',
    marginBottom: theme.spacing(2),
  },
  copyIcon: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#202020'
  },
}));


const VideosList = (
  {
    uploadS3Video,
    addVideo,
    updateVideo,
    getVideos,
    refetch,
    setSaveData,
    saveData,
    showAlert,
    removeFileFromS3,
    copyS3File
  }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (getVideos && getVideos.length > 0) {
      const videos = getVideos.map((video: any, index: any) => ({
        key: index.toString(),
        id: video?.id,
        videoUrl: video.videoUrl,
        videoName: video.videoName,
      }));
      setDataSource(videos);
    }
  }, [getVideos]);

  const handleFileSelect = (file: File | null) => {
    if (!file) return;

    const newRecord = {
      key: Date.now().toString(),
      id: null,
      videoUrl: URL.createObjectURL(file),
      videoName: file.name,
      file,
    };

    setDataSource((prev: any) => [...prev, newRecord]);
    setModalIsOpen(false);
  };

  const handleCellChange = (key: any, value: any) => {
    setDataSource((prev: any) =>
      prev.map((item: any) =>
        item.key === key ? { ...item, videoName: value } : item
      )
    );
  };

  const extractKeyFromS3Url = (s3Url: string) => {
    const url = new URL(s3Url);
    return url.pathname.substring(1);
  };

  const isFileNameExists = (fileName: string, currentVideoId = null) => {
    return getVideos.some((video: any) =>
      video.videoName === fileName && video.id !== currentVideoId
    );
  };

  const handleSave = async (record: any) => {
    setSaveData(true);
    try {
      if (isFileNameExists(record.videoName, record.id)) {
        await showAlert('ファイル名はすでに存在する！', 'error');
        setSaveData(false);
        return;
      }
      if (record.file) {
        const fileName = record.videoName;
        const uploadedUrl = await uploadS3Video(record.file, fileName);
        if (uploadedUrl) {
          const baseUrl = uploadedUrl.substring(0, uploadedUrl.lastIndexOf('/') + 1);
          const encodedFileName = encodeURIComponent(fileName);
          const encodedUrl = `${baseUrl}${encodedFileName}`;
          if (!record.id) {
            await addVideo({
              variables: {
                videoUrl: encodedUrl,
                videoName: record.videoName,
              },
            });
            await showAlert('SUCCESS', 'success');
          }
        }
      } else if (record.id) {
        const oldRecord = getVideos.find((video: any) => video.id === record.id);
        const oldFileName = oldRecord.videoName;
        const newFileName = record.videoName;

        if (oldFileName !== newFileName) {
          const oldFileKey = extractKeyFromS3Url(oldRecord.videoUrl);
          const copyParams = {
            Bucket: REACT_APP_AWS_PUBLIC_BUCKET_VIDEO,
            CopySource: `${REACT_APP_AWS_PUBLIC_BUCKET_VIDEO}/${oldFileKey}`,
            Key: newFileName,
            ACL: 'public-read',
          };
          const result = await copyS3File(copyParams);
          if (!result) {
            await showAlert('Error S3', 'error');
            setSaveData(false);
            return;
          }

          await removeFileFromS3(REACT_APP_AWS_PUBLIC_BUCKET_VIDEO, oldFileKey);
          const baseUrl = oldRecord.videoUrl.substring(0, oldRecord.videoUrl.lastIndexOf('/') + 1);
          const encodedFileName = encodeURIComponent(newFileName);
          const updatedUrl = `${baseUrl}${encodedFileName}`;
          const {data: {updateVideo: res}} = await updateVideo({
            variables: {
              id: record.id,
              videoUrl: updatedUrl,
              videoName: record.videoName,
            },
          });
          await showAlert(res.message, 'success');
        } else {
          const {data: {updateVideo: res}} = await updateVideo({
            variables: {
              id: record.id,
              videoUrl: record.videoUrl,
              videoName: record.videoName,
            },
          });
          await showAlert(res.message, 'success');
        }
      }
      setSaveData(false);
      await refetch();
    } catch (error) {
      setSaveData(false);
      await showAlert(error, 'error');
    }
  };

  const handleCopy = async () => {
    const textToCopy = dataSource.map((item: any) => item.videoName).join('\n');
    await navigator.clipboard.writeText(textToCopy)
      .then(() => {
        showAlert('Copy success', 'success');
      })
      .catch(err => {
        showAlert(err, 'error');
      });
  };

  const columns = [
    {
      title: '動画',
      dataIndex: 'videoUrl',
      key: 'videoUrl',
      width: '20%',
      render: (videoUrl: any) => <VideoPreview file={videoUrl} />,
    },
    {
      title: 'ファイルン名',
      dataIndex: 'videoName',
      key: 'videoName',
      width: '50%',
      render: (videoName: any, record: any) => (
        <Input
          value={videoName}
          onChange={(e: any) => handleCellChange(record?.key, e.target.value)}
          style={{ width: '100%' }}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      render: (_: any, record: any) => (
        <Button
          variant="outlined"
          onClick={() => handleSave(record)}
        >
          保存
        </Button>
      ),
    },
    {
      dataIndex: 'id',
      key: 'id',
      hidden: true,
    },
  ].filter(item => !item.hidden);

  return (
    <div className={classes.root} style={{opacity: saveData ? '0.3' : '1'}}>
      <div style={{marginTop: '16px'}}>
        <div style={{marginBottom: '6px'}}>ファイル名リスト</div>
        <div className={classes.textareaWrapper}>
          <AntdInput.TextArea
            className={classes.textarea}
            value={dataSource.map((item: any) => item?.videoName).join('\n')}
            readOnly
          />
          <CopyOutlined
            className={classes.copyIcon}
            onClick={handleCopy}
          />
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          rowClassName={() => 'editable-row'}
          pagination={false}
        />
      </Form>
      <VideoModal
        showAlert={showAlert}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onFileSelect={handleFileSelect}
      />
      <div className={classes.buttonAdd}>
        <Button
          onClick={() => setModalIsOpen(true)}
          color="primary"
          variant="contained"
        >
          <PlusOutlined style={{marginRight: '6px'}}/> 追加
        </Button>
      </div>
    </div>
  );
};

export default VideosList