import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {UploadOutlined} from '@ant-design/icons';
import {useDroppable} from "@dnd-kit/core";

const VideoModal = ({isOpen, onRequestClose, onFileSelect, showAlert}: any) => {
  const {setNodeRef, isOver} = useDroppable({
    id: 'droppable',
  });

  const handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type === 'video/mp4') {
      onFileSelect(file);
    } else {
      showAlert('Please select mp4 files only!', 'error');
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === 'video/mp4') {
      onFileSelect(file);
    } else {
      showAlert('Please select mp4 files only!', 'error');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      maxWidth="xl"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '900px',
            height: '600px',
            gap: '24px',
          }}
        >
          <h2
            style={{
              fontWeight: '500',
              color: '#202020',
              fontSize: '36px',
            }}
          >
            動画のアップロード
          </h2>

          <div
            ref={setNodeRef}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              width: '200px',
              height: '200px',
              borderRadius: '50%',
              border: '2px dashed #ccc',
              backgroundColor: isOver ? 'lightgreen' : '#f5f5f5',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{fontSize: '96px', color: '#808485'}}>
              <UploadOutlined/>
            </span>
          </div>

          <p
            style={{
              color: '#202020',
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: '500',
            }}
          >
            アップロードする動画ファイルを
            <br/>
            ドラッグ＆ドロップします
          </p>

          <label
            htmlFor="fileInput"
            style={{
              padding: '6px 20px',
              fontWeight: '600',
              backgroundColor: '#969696',
              border: '1px solid #ccc',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#f0f0f0',
              borderRadius: '6px',
            }}
          >
            ファイルを選択
          </label>
          <input
            type="file"
            id="fileInput"
            accept="video/mp4"
            style={{display: 'none'}}
            onChange={handleFileSelect}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;