// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiInput-underline::before {
  display: none !important;
}
.MuiInput-underline::after {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Coupons/style.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AACA;EACE,wBAAA;AACF","sourcesContent":[".MuiInput-underline::before {\n  display: none !important;\n}\n.MuiInput-underline::after {\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
