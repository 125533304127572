import React from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MySnackBar } from '@src/components/MySnackBar';
import { ProductsComponent1 } from '@src/components/Products/component';

class Products extends React.Component<any, any> {
  state: any = {
    isModelOpen: false,
    editProductId: null,
    name: '',
    nameEn: '',
    nameKr: '',
    nameCn: '',
    nameCnTraditional: '',
    nameVn: '',
    code: '',
    description: '',
    price: '',
    duration: '',
  };

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => {
    this.setState({
      isModelOpen: false,
      editProductId: null,
      name: '',
      nameEn: '',
      code: '',
      description: '',
      price: '',
      duration: '',
      nameKr: '',
      nameCn: '',
      nameCnTraditional: '',
      nameVn: '',
    });
  };

  addProduct = async () => {
    const checkName = await this.props.productNameExists({
      variables: {
        name: this.state.name,
      },
    });
    if (checkName?.data?.productNameExists) {
      this.props.showAlert('商品名は確かにある。', 'error');
      return;
    }
    const checkNameEn = await this.props.productNameEnExists({
      variables: {
        nameEn: this.state.nameEn,
      },
    });
    if (checkNameEn?.data?.productNameEnExists) {
      this.props.showAlert('製品名（英語）は確かに存在する', 'error');
      return;
    }
    if (this.state.nameKr) {
      const checkNameKr = await this.props.productNameKrExists({
        variables: {
          nameKr: this.state.nameKr,
        },
      });
      if (checkNameKr?.data?.productNameKrExists) {
        this.props.showAlert('製品名（韓国語）が確かに存在しています', 'error');
        return;
      }
    }
    if (this.state.nameCn) {
      const checkNameCn = await this.props.productNameCnExists({
        variables: {
          nameCn: this.state.nameCn,
        },
      });
      if (checkNameCn?.data?.productNameCnExists) {
        this.props.showAlert(
          '製品名（中国語：簡体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCnTraditional) {
      const checkNameCnTraditional =
        await this.props.productNameCnTraditionalExists({
          variables: {
            nameCnTraditional: this.state.nameCnTraditional,
          },
        });
      if (checkNameCnTraditional?.data?.productNameCnTraditionalExists) {
        this.props.showAlert(
          '製品名（中国語：繁体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameVn) {
      const checkNameVn = await this.props.productNameVnExists({
        variables: {
          nameVn: this.state.nameVn,
        },
      });
      if (checkNameVn?.data?.productNameVnExists) {
        this.props.showAlert(
          '製品名（ベトナム語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    await this.props.addProduct({
      variables: {
        data: {
          name: this.state.name,
          code: this.state.code,
          nameEn: this.state.nameEn,
          description: this.state.description,
          price: parseInt(this.state.price, 10),
          duration: parseInt(this.state.duration, 10),
          nameKr: this.state.nameKr,
          nameCn: this.state.nameCn,
          nameCnTraditional: this.state.nameCnTraditional,
          nameVn: this.state.nameVn,
        },
      },
    });
    await this.props.productsQuery.refetch();
    this.setState((prevState) => ({
      isModelOpen: !prevState.isModelOpen,
    }));
    this.props.history.push('/products');
  };

  setUpdateProduct = (product) => {
    const { isModelOpen } = this.state;
    this.setState({
      isModelOpen: !isModelOpen,
      editProductId: product.id,
      name: product.name,
      code: product.code,
      price: product.price,
      nameEn: product.nameEn,
      nameKr: product.nameKr,
      nameCn: product.nameCn,
      nameCnTraditional: product.nameCnTraditional,
      nameVn: product.nameVn,
      duration: product.duration,
      description: product.description,
    });
  };

  updateProduct = async () => {
    const checkName = await this.props.productNameExists({
      variables: {
        name: this.state.name,
        id: this.state.editProductId,
      },
    });
    if (checkName?.data?.productNameExists) {
      this.props.showAlert('商品名は確かにある。', 'error');
      return;
    }
    const checkNameEn = await this.props.productNameEnExists({
      variables: {
        nameEn: this.state.nameEn,
        id: this.state.editProductId,
      },
    });
    if (checkNameEn?.data?.productNameEnExists) {
      this.props.showAlert('製品名（英語）は確かに存在する', 'error');
      return;
    }
    if (this.state.nameKr) {
      const checkNameKr = await this.props.productNameKrExists({
        variables: {
          nameKr: this.state.nameKr,
          id: this.state.editProductId,
        },
      });
      if (checkNameKr?.data?.productNameKrExists) {
        this.props.showAlert('製品名（韓国語）が確かに存在しています', 'error');
        return;
      }
    }
    if (this.state.nameCn) {
      const checkNameCn = await this.props.productNameCnExists({
        variables: {
          nameCn: this.state.nameCn,
          id: this.state.editProductId,
        },
      });
      if (checkNameCn?.data?.productNameCnExists) {
        this.props.showAlert(
          '製品名（中国語：簡体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameCnTraditional) {
      const checkNameCnTraditional =
        await this.props.productNameCnTraditionalExists({
          variables: {
            nameCnTraditional: this.state.nameCnTraditional,
            id: this.state.editProductId,
          },
        });
      if (checkNameCnTraditional?.data?.productNameCnTraditionalExists) {
        this.props.showAlert(
          '製品名（中国語：繁体字）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    if (this.state.nameVn) {
      const checkNameVn = await this.props.productNameVnExists({
        variables: {
          nameVn: this.state.nameVn,
          id: this.state.editProductId,
        },
      });
      if (checkNameVn?.data?.productNameVnExists) {
        this.props.showAlert(
          '製品名（ベトナム語）が確かに存在しています',
          'error',
        );
        return;
      }
    }
    try {
      await this.props.updateProduct({
        variables: {
          productId: this.state.editProductId,
          data: {
            name: this.state.name,
            code: this.state.code,
            nameEn: this.state.nameEn,
            nameKr: this.state.nameKr,
            nameCn: this.state.nameCn,
            nameCnTraditional: this.state.nameCnTraditional,
            nameVn: this.state.nameVn,
            description: this.state.description,
            price: parseInt(this.state.price, 10),
            duration: parseInt(this.state.duration, 10),
          },
        },
      });
      await this.props.productsQuery.refetch();
      this.setState({ editProductId: null });
      this.setState((prevState) => ({
        isModelOpen: !prevState.isModelOpen,
      }));
      this.props.history.push('/products');
    } catch (err) {
      console.log(err);
    }
  };

  handleOpen = () => {
    this.setState({
      isModelOpen: true,
    });
  };

  render() {
    const {
      productsQuery: { products, loading, error },
      isSystemAdmin,
    } = this.props;
    if (loading) return <CircularProgress />;
    if (error)
      return (
        <MySnackBar variant="error" message={error.graphQLErrors[0].message} />
      );
    return (
      <ProductsComponent1
        onChangeHandler={this.onChangeHandler}
        handleClose={this.handleClose}
        handleOpen={this.handleOpen}
        addProduct={this.addProduct}
        updateProduct={this.updateProduct}
        setUpdateProduct={this.setUpdateProduct}
        state={this.state}
        isSystemAdmin={isSystemAdmin}
        products={products}
      />
    );
  }
}

const productsQuery = gql`
  query productsQuery {
    products {
      id
      name
      nameEn
      nameKr
      nameCn
      nameCnTraditional
      nameVn
      code
      description
      price
      duration
    }
  }
`;
const addProduct = gql`
  mutation ADD_PRODUCT($data: AddProductInput!) {
    addProduct(data: $data) {
      id
    }
  }
`;
const updateProduct = gql`
  mutation updateProduct($productId: ID!, $data: ProductUpdateInput) {
    updateProduct(productId: $productId, data: $data) {
      id
    }
  }
`;

const productNameExists = gql`
  mutation productNameExists($name: String!, $id: String) {
    productNameExists(name: $name, id: $id)
  }
`;

const productNameEnExists = gql`
  mutation productNameEnExists($nameEn: String!, $id: String) {
    productNameEnExists(nameEn: $nameEn, id: $id)
  }
`;

const productNameKrExists = gql`
  mutation productNameKrExists($nameKr: String!, $id: String) {
    productNameKrExists(nameKr: $nameKr, id: $id)
  }
`;

const productNameCnExists = gql`
  mutation productNameCnExists($nameCn: String!, $id: String) {
    productNameCnExists(nameCn: $nameCn, id: $id)
  }
`;

const productNameCnTraditionalExists = gql`
  mutation productNameCnTraditionalExists(
    $nameCnTraditional: String!
    $id: String
  ) {
    productNameCnTraditionalExists(
      nameCnTraditional: $nameCnTraditional
      id: $id
    )
  }
`;

const productNameVnExists = gql`
  mutation productNameVnExists($nameVn: String!, $id: String) {
    productNameVnExists(nameVn: $nameVn, id: $id)
  }
`;

export const ProductsComponent = compose(
  graphql(updateProduct, { name: `updateProduct` }),
  graphql(addProduct, { name: `addProduct` }),
  graphql(productsQuery, {
    name: 'productsQuery',
    options: { fetchPolicy: 'network-only' },
  }),
  graphql(productNameExists, {
    name: 'productNameExists',
  }),
  graphql(productNameEnExists, {
    name: 'productNameEnExists',
  }),
  graphql(productNameKrExists, {
    name: 'productNameKrExists',
  }),
  graphql(productNameCnExists, {
    name: 'productNameCnExists',
  }),
  graphql(productNameCnTraditionalExists, {
    name: 'productNameCnTraditionalExists',
  }),
  graphql(productNameVnExists, {
    name: 'productNameVnExists',
  }),
)(Products);
