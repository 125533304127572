import {useEffect, useRef} from "react";

const VideoPreview = ({ file }: any) => {
  const videoRef = useRef<any>(null);
  const videoUrl = typeof file === 'string' ? file : URL.createObjectURL(file);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = 0.3;
    }
  }, []);

  return (
    <div>
      <video ref={videoRef} loop={true} autoPlay={false} width="400" controls src={videoUrl} />
    </div>
  );
};

export default VideoPreview